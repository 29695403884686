import React, {useState, useEffect} from "react";
import "./TabFor.scss";
import {getTabFor} from "../Api/GetApi";
import {Link} from "@material-ui/core";

function TabFor(props) {
  const [tabFor, setTabFor] = useState([]);
  const [sliceCount, setSliceCount] = useState(5);
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    getTabFor().then(res => {
      setTabFor(res.data);
    });
  }, []);

  return (
    <div className="for-section">
      {tabFor?.slice(0, sliceCount).map((item, index) => {
        return (
          <>
            <div className="for-content" key={index}>
              <div className=" for-title">
                <p className="sub-text">{item.title}</p>
              </div>
              <div className="d-flex">
                <button
                  className={`popular-btn
                 ${props.index === 1 ? "green-bg" : ""}`}>
                  <span className="like-icon cw">
                    <i class="bx bx-like"></i>
                  </span>
                  For{" "}
                  <span className="number cw">
                    {item.for_count === null ? "0" : item.for_count}
                  </span>{" "}
                </button>

                <button className="popular-btn btn-against">
                  <span className="btn-points against-icon">
                    <i class="bx bx-dislike"></i>
                  </span>
                  Against{" "}
                  <span className="btn-points number-cw">
                    {item.against_count === null ? "0" : item.against_count}
                  </span>
                </button>
              </div>
            </div>
          </>
        );
      })}
      {tabFor.length > 5 && showButton === true ? (
        <Link to="/my-profile">
          <button
            className="btn- btn-see"
            onClick={() => {
              setSliceCount(tabFor.length);
              setShowButton(false);
            }}>
            See More
          </button>
        </Link>
      ) : (
        ""
      )}
    </div>
  );
}

export default TabFor;
