//@ts-check
import React, {useContext, useState, useEffect} from "react";
import {Formik, Form, Field} from "formik";
import {domain} from "../Api/AxiosInstance";
import TimeAgo from "timeago-react";
import * as Yup from "yup";
import {
  postDeleteComment,
  postSubComment,
  postEditComment,
} from "../Api/PostApi";
import {GlobalContext} from "../Context/GlobalContext";
import {placeholderImageUrl, useSubComments, useUserInfo} from "../Api/GetApi";
import {CustomPopUp} from "../CustomPopUp/CustomPopUp";

/**@type {React.FC<{commentId:number}>} */
export const CommentArea = ({commentId}) => {
  const {successMessage, errorMessage} = useContext(GlobalContext);
  const [visible, setVisible] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [subComment, setSubComment] = useState("");
  const [commentDeleted, setCommentDeleted] = useState(false);

  const [id, setId] = useState("");

  const {data: subComments, refetch: loadSubComments} =
    useSubComments(commentId);

  const {data: userDetails} = useUserInfo();
  console.log({userDetails});

  useEffect(() => {
    loadSubComments();
  }, [loadSubComments]);

  const openModal = props => {
    setVisible(true);
    setSubComment(props);
  };
  const closeModal = () => {
    setVisible(false);
  };

  const deleteComment = id => {
    postDeleteComment(id).then(res => {
      if (res.data.status === 200) {
        setVisible(false);
        successMessage(res.data.message);
        loadSubComments();
      } else {
        errorMessage(res.data.message);
      }
    });
  };

  const editComment = (id, comment) => {
    postEditComment(id, comment).then(res => {
      if (res.data.status === 200) {
        setVisible(false);
        successMessage("Message edited successfully");
        loadSubComments();
      } else {
        errorMessage(res.data.message);
      }
    });
  };

  return (
    <div>
      <div id="example-collapse-text">
        <div className="comment-area">
          {subComments.map((itemDetails, index) => {
            return (
              <>
                <div className="comment-section" key={index}>
                  <div className="comment-image">
                    <img
                      src={
                        itemDetails.image
                          ? domain + "/uploads/" + itemDetails?.image
                          : placeholderImageUrl
                      }
                      alt="img"
                    />
                  </div>
                  <div className="comment-box" key={index}>
                    <div className="comment-block">
                      <p className="content">{itemDetails.sub_comment}</p>
                      <div className="comment-info-block">
                        <p className="name">
                          {itemDetails.name}
                          <span className="dot"></span>
                          <span>
                            <TimeAgo datetime={itemDetails.created_at} />
                          </span>
                        </p>
                      </div>
                    </div>
                    {/* {subCommentDeleted === itemDetails.user_front_id ? ( */}
                    {userDetails?.user_front_id ===
                      itemDetails.user_front_id && (
                      <div className="popup-icons">
                        <i
                          className="bx bx-edit popup-edit"
                          onClick={() => {
                            openModal(itemDetails.sub_comment);

                            setId(itemDetails.id);
                          }}></i>

                        <i
                          className="bx bx-trash popup-delete"
                          onClick={() => {
                            setCommentDeleted(true);
                            openModal();
                            setVisible(true);
                            setDeletePopUp(true);
                            setId(itemDetails.id);
                          }}></i>
                      </div>
                    )}

                    {/* ) : (
                      <></>
                    )} */}
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <Formik
          initialValues={{
            sub_comment: "",
          }}
          validationSchema={Yup.object({
            sub_comment: Yup.string()
              .min(2, "Must be 2 characters or more")
              .required("Required"),
          })}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            const postData = {
              sub_comment: values.sub_comment,
              comment_id: commentId,
            };

            postSubComment(postData).then(res => {
              if (res.status === 200) {
                resetForm({
                  values: {
                    sub_comment: "",
                  },
                });
                loadSubComments();
                //setLikeUpdate(!likeUpdate);
              } else {
                errorMessage(res.data.message);
              }
            });
            setSubmitting(false);
          }}>
          {formik => {
            return (
              <Form>
                <div className="comment-section">
                  <div className="comment-image">
                    <img
                      src={
                        userDetails?.image
                          ? domain + "/uploads/" + userDetails.image
                          : placeholderImageUrl
                      }
                      alt="img"
                    />
                  </div>

                  <div className="comment-box">
                    <Field
                      type="text"
                      name="sub_comment"
                      placeholder="Write a comment .........."
                      className="white-block  comment-field"
                      // onKeyDown={handleKeyDown()}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <CustomPopUp
        visible={visible}
        closeModal={() => closeModal()}
        type={deletePopUp ? "delete" : "edit"}
        action={deletePopUp ? deleteComment : editComment}
        id={id}
        subComment={subComment}
        commentDeleted={commentDeleted}
      />
    </div>
  );
};
