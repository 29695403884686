import React, {useState, useEffect} from "react";
import "./ProfileModerator.scss";
import {Link, Redirect} from "react-router-dom";
import {getProfile} from "../Api/GetApi";
import {isAuthenticate} from "../../Utils/Utils";
import {domain} from "../Api/AxiosInstance";
import MetaConfigure from "../Helmet/Helmet";

function ProfileModerator() {
  // const [buttonrequested, setButtonRequested] = useState(true);
  const [userDetail, setUserDetail] = useState("");

  useEffect(() => {
    getProfile().then(res => {
      setUserDetail(res.data[0]?.moderator_status);
    });
  }, []);

  if (isAuthenticate()) {
    return <Redirect to="/" />;
  }

  const handleOnClick = () => {
    window.open(domain, "blank");
  };

  return (
    <div className="profile-moderator-section section-gap">
      <MetaConfigure title="Join Our Team Of Moderators - Mass Debaters" />

      <div className="container">
        <div className="card-section">
          <div className="profile-moderator-content">
            <div className="row justify-content-md-center">
              <div className="col-md-7">
                <h1 className="main-title"> Join Our Team of Moderators</h1>
                <p>
                  Our moderators keep the site going by ensuring debates can
                  continue but in a way that is respectful, informative, clear
                  and concise. As a moderator you will have great power and with
                  that comes great responsibility. You will need to demonstrate
                  an ability to remain unbiased when assessing new arguments and
                  make sure that any new debates or arguments adhere to the Mass
                  Debaters Rules & Policies which can be found here
                </p>
                <p>
                  If you would like to become a moderator, you can do so by
                  completing a simple application to show you understand our
                  Rules & Policies.
                </p>
                <p> All rules for checklist above except for this line:</p>

                <p>
                  Failure to follow these guidelines as a moderator will lead to
                  losing those privileges and possibly being banned as a site
                  user
                </p>

                {userDetail === null ? (
                  <Link to="become-moderator">
                    <button className="primary-btn">Become a Moderator</button>
                  </Link>
                ) : userDetail === "pending" ? (
                  <button className="primary-btn">Requested</button>
                ) : userDetail === "rejected" ? (
                  <button className="primary-btn">
                    Your request has been rejected.
                  </button>
                ) : (
                  <>
                    <button
                      className="primary-btn"
                      onClick={() => handleOnClick()}>
                      You have already became a moderator!
                      <span> Click Here to continue</span>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileModerator;
