import {isAuthenticate} from "../Utils/Utils";
import {disLike, Like, undecide} from "../components/Api/PostApi";
import {toast} from "react-toastify";

/**
 *
 * @param {number} debate_id
 * @param {number} user_front_id
 * @param {()=> void} user_front_id
 * @returns
 */
export const debateDisLike = (debate_id, user_front_id, callback) => {
  if (isAuthenticate()) {
    return toast.error("Please login to Continue");
  }
  const postData = {
    debate_id: debate_id,
    user_front_id: user_front_id,
  };
  disLike(postData).then(res => {
    if (res.status === 200) {
      callback();
      return;
    }
  });
};

/**
 *
 * @param {number} debate_id
 * @param {number} user_front_id
 * @param {()=> void} user_front_id
 * @returns
 */
export const debateLike = (debate_id, user_front_id, callback) => {
  if (isAuthenticate()) {
    return toast.error("Please login to Continue");
  }
  const postData = {
    debate_id: debate_id,
    user_front_id: user_front_id,
  };
  Like(postData).then(res => {
    if (res.status === 200) {
      callback();
      return;
    }
  });
};

export const debateUndecide = (debate_id, user_front_id, callback) => {
  if (isAuthenticate()) {
    return toast.error("Please login to Continue");
  }
  const postData = {
    debate_id: debate_id,
    user_front_id: user_front_id,
  };
  undecide(postData).then(res => {
    if (res.status === 200) {
      callback();
      return;
    }
  });
};
