import React, {useContext} from "react";
import "./ConfirmPassword.scss";
import {Form, Formik, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import {useParams} from "react-router-dom";
import {postPasswordReset} from "../Api/PostApi";
import {GlobalContext} from "../Context/GlobalContext";

import {useHistory} from "react-router-dom";
import Loader from "react-loader-spinner";

export const ConfirmPassword = () => {
  const {successMessage, errorMessage} = useContext(GlobalContext);
  let history = useHistory();

  const {token} = useParams();
  return (
    <div className="container">
      <Formik
        initialValues={{
          newpassword: "",
          confirmpassword: "",
        }}
        validationSchema={Yup.object({
          new_password: Yup.string().required("Required"),

          confirm_password: Yup.string().when("new_password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("new_password")],
              "Password Doesn't Match"
            ),
          }),
        })}
        onSubmit={(values, {setSubmitting, resetForm}) => {
          setSubmitting(true)
          var formData = new FormData();
          formData.append("new_password", values.new_password);
          formData.append("token", token);
          postPasswordReset(formData).then(response => {
            if (response.data.status === 200) {
              successMessage(response.data.message);
              history.push("/");
            } else {
              errorMessage(response.data.message);
            }
          }).finally((setSubmitting(false)));
        }}>{formik=>(
<Form>
          <div class="contents">
            <label> New Password</label>
            <Field
              name="new_password"
              type="password"
              placeholder="Enter your Password"
              className="input-field"
            />
            <span className="error">
              <ErrorMessage name="new_password" />
            </span>
          </div>

          <div class="contents">
            <label>Confirm Password</label>
            <Field
              name="confirm_password"
              type="password"
              placeholder="Confirm Password"
              className="input-field"
            />
            <span className="error">
              <ErrorMessage name="confirm_password" />
            </span>
          </div>
          <button className="main-btn" type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting
            ?
            <Loader width={70} height={25} color="#FFFFFF" />
            :<span>Submit</span>

          }
            
          </button>
        </Form>
        )}
        
      </Formik>
    </div>
  );
};
