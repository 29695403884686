import React, {useEffect} from "react";
import {useDebates} from "../Api/GetApi";
import Explore from "../explore/explore";
import Popular from "../popular/popular";
import {postIPAddress} from "../Api/PostApi";
import axios from "axios";

export const Index = () => {
  const {data: debateData, refetch: refetchDebateData} = useDebates();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await axios.get("https://json.geoiplookup.io/");
      //post ip address
      var postData = {
        location: res.data?.country_name,
        ip: res.data?.ip,
        country_code: res.data?.country_code,
      };
      postIPAddress(postData)
        .then(res => {
          if (res.data === 200) {
            console.log("IP post success");
            return;
          }
          console.log("IP post unsuccess");
        })
        .catch(err => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="home-page">
        <Explore />
        <Popular debateData={debateData} refetch={refetchDebateData} />
      </div>
    </>
  );
};
