import React from "react";
import "./RulesPolicies.scss";
import {Link} from "react-router-dom";
import MetaConfigure from "../Helmet/Helmet";

function RulesPolicies() {
  return (
    <div className="rulespolicies-section section-gap">
      <MetaConfigure title="Rules-policies - Mass Debaters" />
      <div className="container">
        <div className="card-section">
          <div className="rulespolicies-content">
            <h1 className="main-title">Rules And Policies</h1>

            <div className="moderator-text">
              <p className="sub-text">
                At Massdebaters, accessible from https://www.massdebaters.com/,
                one of our main priorities is the privacy of our visitors. This
                Privacy Policy document contains types of information that is
                collected and recorded by Massdebaters and how we use it.
              </p>
              <p className="sub-text">
                {" "}
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.
              </p>

              <p className="sub-text">
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collected in Massdebaters.
                This policy is not applicable to any information collected
                offline or via channels other than this website.
              </p>
            </div>
            <div className=" moderator-text">
              <h1>Consent</h1>
              <p className="sub-text">
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </p>
            </div>
            <div className=" moderator-text">
              <h1>Information we collect</h1>
              <p className="sub-text">
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to
                you at the point we ask you to provide your personal
                information.
              </p>

              <p className="sub-text">
                If you contact us directly, we may receive additional
                information about you such as your name, email address, phone
                number, the contents of the message and/or attachments you may
                send us, and any other information you may choose to provide.
              </p>

              <p className="sub-text">
                When you register for an Account, we may ask for your contact
                information, including items such as name, company name,
                address, email address, and telephone number.
              </p>
            </div>
            <div className=" moderator-text">
              <h1>How we use your information</h1>
              <h2>
                We use the information we collect in various ways, including to:
              </h2>

              <ul>
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalise, and expand our website </li>
                <li>Understand and analyse how you use our website</li>
                <li>
                  Develop new products, services, features, and functionality
                </li>
                <li>
                  Communicate with you, either directly or through one of our
                  partners, including for customer service, to provide you with
                  updates and other information relating to the website, and for
                  marketing and promotional purposes
                </li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
              </ul>
            </div>
            <div className=" moderator-text">
              <h1>Log Files</h1>

              <p className="sub-text">
                Mass Debaters follow a standard procedure of using log files.
                These files log visitors when they visit websites. All hosting
                companies do this as part of hosting services' analytics. The
                information collected by log files include internet protocol
                (IP) addresses, browser type, Internet Service Provider (ISP),
                date and time stamp, referring/exit pages, and possibly the
                number of clicks. These are not linked to any information that
                is personally identifiable. The purpose of the information is
                for analysing trends, administering the site, tracking users'
                movement on the website, and gathering demographic information.
              </p>
            </div>
            <div className="moderator-text">
              <h1>Cookies and Web Beacons</h1>

              <p className="sub-text">
                Like any other website, Massdebaters uses 'cookies'. These
                cookies are used to store information including visitors'
                preferences, and the pages on the website that the visitor
                accessed or visited. The information is used to optimise the
                users' experience by customising our web page content based on
                visitors' browser type and/or other information.
              </p>
            </div>
            <div className="moderator-text">
              <h1>Third Party Privacy Policies</h1>

              <p className="sub-text">
                Mass Debaters Privacy Policy does not apply to other advertisers
                or websites. Thus, we are advising you to consult the respective
                Privacy Policies of these third-party ad servers for more
                detailed information. It may include their practices and
                instructions about how to opt-out of certain options.
              </p>

              <p className="sub-text">
                You can choose to disable cookies through your individual
                browser options. To know more detailed information about cookie
                management with specific web browsers, it can be found at the
                browsers' respective websites.
              </p>
            </div>
            <div className="moderator-text">
              <h1>Information from Third-Party Social Media Services</h1>

              <p className="sub-text">
                The Company allows You to create an account and log in to use
                the Service through the following Third-party Social Media
                Services:
              </p>

              <ul>
                <li>Google</li>
                <li>Facebook</li>
              </ul>

              <p className="sub-text">
                If You decide to register through or otherwise grant us access
                to a Third-Party Social Media Service, We may collect Personal
                data that is already associated with Your Third-Party Social
                Media Service's account, such as Your name, Your email address,
                Your activities or Your contact list associated with that
                account.
              </p>

              <p className="sub-text">
                You may also have the option of sharing additional information
                with the Company through Your Third-Party Social Media Service's
                account. If You choose to provide such information and Personal
                Data, during registration or otherwise, You are giving the
                Company permission to use, share, and store it in a manner
                consistent with this Privacy Policy.
              </p>
            </div>
            <div className="moderator-text">
              <h1>CCPA Privacy Rights (Do Not Sell My Personal Information)</h1>

              <p className="sub-text">
                Under the CCPA, among other rights, California consumers have
                the right to:
              </p>

              <p className="sub-text">
                Request that a business that collects a consumer's personal data
                disclose the categories and specific pieces of personal data
                that a business has collected about consumers.
              </p>

              <p>
                Request that a business delete any personal data about the
                consumer that a business has collected.
              </p>

              <p>
                Request that a business that sells a consumer's personal data,
                not sell the consumer's personal data.
              </p>

              <p>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </p>
            </div>
            <div className="moderator-text">
              <h1>GDPR Data Protection Rights</h1>
              <p className="sub-text">
                We would like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the following:
              </p>
              <p className="sub-text">
                The right to access – You have the right to request copies of
                your personal data. We may charge you a small fee for this
                service.
              </p>
              <p className="sub-text">
                The right to rectification – You have the right to request that
                we correct any information you believe is inaccurate. You also
                have the right to request that we complete the information you
                believe is incomplete.
              </p>
              <p className="sub-text">
                {" "}
                The right to erasure – You have the right to request that we
                erase your personal data, under certain conditions. The right to
                restrict processing – You have the right to request that we
                restrict the processing of your personal data, under certain
                conditions.
              </p>
              <p>
                The right to object to processing – You have the right to object
                to our processing of your personal data, under certain
                conditions.
              </p>
              <p>
                {" "}
                The right to data portability – You have the right to request
                that we transfer the data that we have collected to another
                organisation, or directly to you, under certain conditions.
              </p>
              <p>
                {" "}
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </p>
            </div>
            <div className="moderator-text">
              <h1>Children's Information</h1>
              <p>
                {" "}
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.{" "}
              </p>

              <p>
                Massdebaters does not knowingly collect any Personal
                Identifiable Information from children under the age of 13. If
                you think that your child provided this kind of information on
                our website, we strongly encourage you to contact us immediately
                and we will do our best efforts to promptly remove such
                information from our records.
              </p>
            </div>
            <div className="moderator-text">
              <h1>Contact Us</h1>

              <p className="sub-text">
                If you have any questions about this Privacy Policy, You can
                contact us:
              </p>

              <ul>
                <li>By email: noreply@massdebaters.com</li>

                <li>
                  By visiting this page on our website:
                  https://www.massdebaters.com/
                </li>
              </ul>
            </div>
            <Link to="/">Back to Homepage</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RulesPolicies;
