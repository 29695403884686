import React, {useEffect, useState} from "react";
import "./CustomPopUp.scss";

export const EditPopUp = props => {
  const [comment, setComment] = useState("");
  useEffect(() => {
    setComment(props?.subComment);
  }, [props?.subComment]);
  return (
    <div class="container">
      <div className="confirmtion-popup popup-padding  ">
        <h5 className="popup-title">Edit Comment</h5>
        <input
          className="form-control"
          value={comment}
          onChange={e => setComment(e.target.value)}
          type="text"
        />
        <div className="btn-block btn-line ">
          <button className="cancel-btn">Cancel</button>
          <button
            className="main-btn"
            onClick={() => {
              props.action(props.id, comment);
            }}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
