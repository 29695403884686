import "./EmailVerification.scss";
import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getEmailVerfied} from "../Api/GetApi";
// import {catchError} from "../utils/catchError";

export const EmailVerification = () => {
  const [loading, setLoading] = useState(true);
  const {id} = useParams();
  const history = useHistory();
  const userEmailVerification = id => {
    getEmailVerfied(id)
      .then(res => {
        toast.success(res.data.message);
        localStorage.setItem("isAuthenticate", true);
        history.push("/");
        setTimeout(() => {
          window.location.reload("");
        }, 1000);
        setLoading(false);
      })
      .catch(err => {
        if (err.response.status === 400) {
          toast.error(err.response.data.message);
          history.push("/");
        }
      });
  };
  useEffect(() => {
    userEmailVerification(id);
  }, []);

  return (
    <>
      {loading ? (
        <div className="verifying-page">
          <div className="varification-card">
            <div className="center-div">
              {/* <div className="navbar-brand">
                <img src={require("../../images/mero-bid2.png")} alt="logo" />
              </div> */}
              <div>
                <img
                  src={require("../../gif/loading.gif").default}
                  className="loading border-radius"
                  alt="loading"
                />
              </div>
              <h3 className="big-font-title">
                Verifying your email address...
              </h3>
            </div>
          </div>
        </div>
      ) : (
        <div className="verifying-page">
          <div className="varification-card">
            <div className="center-div">
              <p className="logo">
                Mass <span className="nav-title">Debaters</span>
              </p>
              {/* <div className="navbar-brand">
               <img src={require("../../images/mero-bid2.png")} alt="logo" />
              </div> */}
              <div className="mg-20">
                <img
                  src={require("../../gif/money.gif").default}
                  className="loading border-radius"
                  alt="loading"
                />
              </div>
              <h3 className="big-font-title">Redirecting to your page...</h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
