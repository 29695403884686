import React, {useContext} from "react";
import {Formik, Form, Field, ErrorMessage} from "formik";
import "./ForgotPasswod.scss";

import {postPasswordForget} from "../Api/PostApi";
import * as Yup from "yup";

import {GlobalContext} from "../Context/GlobalContext";
import Loader from "react-loader-spinner";

const ResetPassword = props => {
  const {successMessage, errorMessage} = useContext(GlobalContext);
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Invalid email addresss")
          .required("Required!!"),
      })}
      onSubmit={(values, {setSubmitting, resetForm}) => {
        setSubmitting(true);
        const postData = new FormData();

        postData.append("email", values.email);
        // const postData = {email: values.email};
        postPasswordForget(postData)
          .then(res => {
            if (res.data.status === 200) {
              successMessage(res.data.message);
              setTimeout(() => {}, 5000);
              resetForm({
                values: {
                  email: "",
                },
              });
            } else {
              errorMessage(res.data.message);
            }
          })
          .catch(err => errorMessage("Internal server error"))
          .finally(() => setSubmitting(false));
      }}>
      {formik => (
        <Form>
          <div className="container forget-pw">
            <div className="form-group">
              <h1 className="main-title">Forgot password?</h1>
              <p class="gray-text hidden-block">
                We will send a recovery email with a link to reset your password
              </p>
              <label className="email-title">Email</label>
              <Field
                name="email"
                type="email"
                placeholder="Enter email"
                className="input-field"
              />
              <span className="error">
                <ErrorMessage name="email" />
              </span>
            </div>
            <div className="bottom-btn">
              <button
                className="main-btn"
                type="submit "
                disabled={formik.isSubmitting}>
                {formik.isSubmitting ? (
                  <Loader width={47} height={20} color="#FFFFFF" />
                ) : (
                  <span>Submit</span>
                )}
              </button>

              <button
                className="cancel-btn"
                onClick={() => {
                  props.closeModal();
                  props.setForgotPassword(false);
                }}
                type="reset">
                Cancel
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPassword;
