import {useContext} from "react";
import Axios from "../Api/AxiosInstance";
import {useQuery} from "react-query";
import {GlobalContext} from "../Context/GlobalContext";
import useSWR, {useSWRConfig} from "swr";

export const getExplore = () => {
  return Axios.get(`explore`);
};

export const useExplore = () => {
  const {errorMessage} = useContext(GlobalContext);
  const {data} = useQuery(["explore-list"], async () => {
    const response = await getExplore();
    if (response.status !== 200) {
      errorMessage("Could not fetch explore data");
    }
    return response.data;
  });
  return data;
};

export const getDebates = () => {
  return Axios.get("popular_debate");
};

export const useDebates = () => {
  const {errorMessage} = useContext(GlobalContext);
  return useQuery("popular-debate", getDebates, {
    select: res => res.data.debate,
    onError: () => {
      errorMessage("Could not fetch popular debate");
    },
    refetchOnMount: true,
  });
};

export const getCountryList = () => {
  return Axios.get("country_list");
};

export const getAllDebates = () => {
  return Axios.get("popular_debate");
};

export const getDebatesList = () => {
  return Axios.get("user/debate_list");
};

export const getSpecificDebate = id => {
  return Axios.get(`specific_debate/${id}`);
};

export const getNotifications = () => {
  return Axios.get(`user_specific_notification`);
};
export const getCategoryList = id => {
  return Axios.get(`category_list`);
};

export const getOpinion = id => {
  return Axios.get(`specific_debate_comment_for/${id}`);
};

export const getAgainst = id => {
  return Axios.get(`specific_debate_comment_against/${id}`);
};

export const getProfile = () => {
  return Axios.get("user/profile");
};

export const getNavigation = id => {
  return Axios.get(`category/explore/debate/${id}`);
};

export const getEmailVerfied = id => {
  return Axios.get(`/confirm_user_front/${id}`);
};

/**
 *
 * @param {string} id
 */
export const useForAgainst = slug => {
  const {errorMessage} = useContext(GlobalContext);

  const fetcher = url =>
    Axios.get(url).then(res => {
      if (!Array.isArray(res.data)) {
        throw new Error("Response is not iterable");
      }
      return res.data;
    });

  const url = `debate/for_against/${slug}`;

  const {mutate} = useSWRConfig();
  const {data, error} = useSWR(url, fetcher, {
    onError: err => {
      errorMessage(err.message || "failed to fetch data");
    },
    errorRetryCount: 3,
  });

  return {
    data: data || [],
    isLoading: !error && !data,
    refetch: () => mutate(url),
  };
};

export const getTabFor = () => {
  return Axios.get("user/debate/for");
};

export const getTabAgainst = () => {
  return Axios.get("user/debate/against");
};

export const getExploreNavigation = id => {
  return Axios.get(`category/explore/${id}`);
};

export const useExploreNavigation = id => {
  const {errorMessage} = useContext(GlobalContext);
  return useQuery(`category/explore/${id}`, () => getExploreNavigation(id), {
    onError: () => {
      errorMessage("Could not fetch explore navigation data");
    },
    select: res => res.data,
  });
};

// export const getForDebate = () => {
//     return Axios.get("debate/like");
// }

export const getUserinfo = () => {
  return Axios.get("user/profile");
};

export const useUserInfo = () => {
  const {errorMessage} = useContext(GlobalContext);

  const fetcher = url =>
    Axios.get(url).then(res => {
      if (!Array.isArray(res.data)) {
        throw new Error("Failed to fetch user profile");
      }
      return res.data[0];
    });

  const url = "user/profile";

  const {mutate} = useSWRConfig();
  const {data, error} = useSWR(url, fetcher, {
    onError: err => {
      errorMessage(err.message || "Failed to fetch user profile");
    },
    errorRetryCount: 3,
  });

  return {
    data: data,
    isLoading: !error && !data,
    refetch: () => mutate(url),
  };
};

export const useSubComments = id => {
  const {errorMessage} = useContext(GlobalContext);
  const fetcher = url =>
    Axios.get(url).then(res => {
      if (
        Array.isArray(res.data) &&
        res.data[0] &&
        res.data[0]?.["json_agg"] === null
      ) {
        return [];
      }
      const data = res.data?.[0]?.sub_comment_details;
      if (Array.isArray(data)) {
        return data;
      } else {
        throw new Error("No sub comment found");
      }
    });

  const url = `sub_comment_list/${id}`;

  const {data, error} = useSWR(url, fetcher, {
    onError: err => {
      errorMessage(err.message || "failed to fetch data");
    },
    errorRetryCount: 3,
  });

  const {mutate} = useSWRConfig();

  return {
    data: data || [],
    refetch: () => mutate(url),
    isLoading: !data && !error,
  };
};

//PW

export const getPasswordReset = () => {
  return Axios.get("password/reset/:token");
};

export const placeholderImageUrl =
  "https://st3.depositphotos.com/1767687/16607/v/600/depositphotos_166074422-stock-illustration-default-avatar-profile-icon-grey.jpg";
