import React, {useContext, useEffect, useState} from "react";
import Modal from "react-awesome-modal";
import {Formik, Form, Field, ErrorMessage} from "formik";
import Signup from "../Signup/Signup";
import * as Yup from "yup";
import {Login, postFacebookLogin, postGoogleLogin} from "../Api/PostApi";
import "./Popup.scss";
import "react-toastify/dist/ReactToastify.css";
import {GlobalContext} from "../Context/GlobalContext";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {GoogleLogin} from "react-google-login";
import ResetPassword from "../ForgotPassword/ForgotPassword";
import Loader from "react-loader-spinner";

const Popup = props => {
  const {successMessage, errorMessage, setUserId, setUserName} =
    useContext(GlobalContext);

  //forcing modal height by rerendering
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    setShowModal(false);
    setTimeout(() => {
      setShowModal(true);
    }, 0);
  }, [props.forgotPassword]);

  const modalHeight = props.forgotPassword ? "275" : "526";

  const onLogin = (values, setSubmitting) => {
    const postData = {
      auth: {
        email: values.email,
        password: values.password,
      },
    };

    Login(postData)
      .then(({data}) => {
        setUserId(data.id);
        setUserName(data.name);
        localStorage.setItem("isAuthenticate", true);
        successMessage("Login successful!");
        setTimeout(() => {
          window.location.reload("");
        }, 1000);
      })
      .catch(error => {
        errorMessage(error.response.data.msg);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const responseFacebook = response => {
    var accessdata = {
      access_token: response?.accessToken,
      id: response?.id,
      name: response?.name,
      image: response?.picture?.data.url,
    };
    postFacebookLogin(accessdata)
      .then(res => {
        setUserId(res.data.data.id);
        setUserName(res.data.data.name);
        localStorage.setItem("isAuthenticate", true);
        successMessage(res.data.message);
        setTimeout(() => window.location.reload(""), 1000);
      })
      .catch(error => {
        errorMessage(error.response.data.message);
      })
      .finally(() => {
        props.closeModal();
      });
  };

  const responseGoogle = res => {
    var postData = {
      name: res.profileObj.name,
      image: res.profileObj.imageUrl,
      email: res.profileObj.email,
      token_id: res.tokenId,
    };
    postGoogleLogin(postData)
      .then(res => {
        setUserId(res.data.data.id);
        setUserName(res.data.data.name);
        localStorage.setItem("isAuthenticate", true);
        successMessage(res.data.message);
        setTimeout(() => window.location.reload(""), 1000);
      })
      .catch(error => {
        errorMessage(error.response.data.message);
      })
      .finally(() => {
        props.closeModal();
      });
  };
  return (
    <div className="popup ">
      {showModal && (
        <Modal
          visible={props.visible}
          width="430"
          height={modalHeight}
          effect="fadeInUp"
          style={{zIndex: "9"}}
          onClickAway={() => props.closeModal()}>
          <>
            {!props?.forgotPassword ? (
              <div className="form-section">
                <div className="space-between">
                  <h3 className="align-itemss">Log in</h3>
                  <span>
                    <div
                      className="close-btn btn"
                      onClick={() => {
                        props.closeModal();
                      }}>
                      x
                    </div>
                  </span>
                </div>
                <div className="social-media">
                  <div className="social-btn-block">
                    <img
                      class="fb-icon"
                      src={require("../../assets/images/fbb.png").default}
                      alt="images"
                    />
                    <FacebookLogin
                      appId="566824048284573"
                      // autoLoad={true}
                      fields="name,email,picture"
                      render={renderProps => (
                        <button
                          onClick={renderProps.onClick}
                          className="social-btn">
                          Continue with Facebook
                        </button>
                      )}
                      callback={response => {
                        responseFacebook(response);
                      }}
                    />
                  </div>
                  <div className="social-btn-block">
                    <img
                      class="google-icon"
                      src={require("../../assets/images/search.png").default}
                      alt="images"
                    />
                    <GoogleLogin
                      clientId="1023434308668-5bt5lnlbfmhlttnshsf2m0803fgjgsmp.apps.googleusercontent.com"
                      buttonText="Login"
                      onSuccess={res => {
                        responseGoogle(res);
                      }}
                      onFailure={error => {
                        console.log(error);
                      }}
                      cookiePolicy={"single_host_origin"}
                      render={renderProps => (
                        <button
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          className="social-btn google-btn">
                          Continue with Google
                        </button>
                      )}
                    />
                  </div>
                </div>
                <h6>OR</h6>
                <div>
                  <div className="form-section ">
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      validationSchema={Yup.object({
                        email: Yup.string()
                          .email("Invalid email addresss`")
                          .required("Required"),

                        password: Yup.string().required("Required"),
                      })}
                      onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true);
                        onLogin(values, setSubmitting);
                      }}>
                      {formik => (
                        <Form>
                          <div className="scroll-bar">
                            <div class="contents">
                              <label className="title">Email </label>
                              <Field
                                name="email"
                                type="email"
                                placeholder="Enter your email"
                                className="input-field"
                              />
                              <span className="error">
                                <ErrorMessage name="email" />
                              </span>
                            </div>
                            <div class="contents">
                              <label className="title">Password</label>
                              <Field
                                name="password"
                                type="password"
                                placeholder="Enter your password"
                                className="input-field"
                              />
                              <span className="error">
                                <ErrorMessage name="password" />
                              </span>
                            </div>
                            <div className="font-500 cw space-between">
                              <label class="checkbox-label">
                                <input type="checkbox" />
                                <span class="checkbox-custom"></span>
                                <span className="pl">Remember me</span>
                              </label>
                              <span
                                onClick={() => {
                                  props.setForgotPassword(true);
                                }}
                                className="cg">
                                Forgot your password?
                              </span>
                            </div>

                            <button
                              type="submit"
                              className="main-btn  login-btn"
                              disabled={formik.isSubmitting}>
                              {formik.isSubmitting ? (
                                <Loader
                                  width={70}
                                  height={25}
                                  color="#FFFFFF"
                                />
                              ) : (
                                <span>Login</span>
                              )}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="flex d-f" style={{flexDirection: "row"}}>
                  <span className="sign-up" onClick={() => props.closeModal()}>
                    You don't have an account?
                  </span>

                  <Signup closeParentPopUp={props.closeModal} />
                </div>
              </div>
            ) : (
              <ResetPassword
                closeModal={() => props.closeModal()}
                setForgotPassword={props.setForgotPassword}
                forgotPassword={props.forgotPassword}
              />
            )}
          </>
        </Modal>
      )}
    </div>
  );
};

export default Popup;
