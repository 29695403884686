import React, {useState, useEffect} from "react";
import "./BecomeModerator.scss";
import {useHistory} from "react-router-dom";
import {BecomeAModerator} from "../Api/PostApi";

const BecomeModerator = () => {
  const history = useHistory();
  const [value1, setValue1] = useState(false);
  const [value2, setValue2] = useState(false);
  const [value3, setValue3] = useState(false);
  const [value4, setValue4] = useState(false);
  const [isCheck, setIsCheck] = useState(true);

  useEffect(() => {
    setIsCheck(!(value1 && value2 && value3 && value4));
  }, [value1, value2, value3, value4]);

  const handleSubmit = () => {
    const postData = new FormData();
    postData.append("id", 1);
    BecomeAModerator(postData).then(res => {
      if (res.data.status === 200) {
        history.push("/thankyou-page");
        return;
      }
      alert("failure");
    });
  };

  // const getCheckboxValue = () =>{
  //
  //   if(value1===true && value2===true && value3===true && value4===true){
  //
  //     setIsCheck(false)
  //   }
  // }

  return (
    <div className="become-moderator-section section-gap">
      <div className="container">
        <div className="card-section">
          <div className="row justify-content-md-center">
            <div className="col-md-11">
              <h1 className="main-title">
                PLEASE CHECK EACH POINTS TO CONFIRM YOU UNDERSTAND
              </h1>
              <div className="become-moderator-content">
                <div className="d-flex">
                  <div className="display">
                    <div className="flex-column">
                      <label class="control control--checkbox">
                        <input
                          class="styled-checkbox"
                          id="styled-checkbox-1"
                          type="checkbox"
                          name="value1"
                          value="value1"
                          onChange={e => {
                            setValue1(e.target.checked);
                          }}
                        />
                        <label for="styled-checkbox-1"></label>
                      </label>

                      <p>I Understand</p>
                    </div>
                    <div className="moderator-text">
                      <h1 className="hide">Keep things civil</h1>
                    </div>
                  </div>

                  <div className=" moderator-text">
                    <h1 className="show">Keep things civil</h1>
                    <h2>No discriminatory language</h2>
                    <p className="sub-text">
                      {" "}
                      You should be mainly be attacking the argument and not the
                      person. However if it is a person who is the subject of a
                      debate then make your arguments based on things they can
                      change (e.g. behaviour, character, actions, public views,
                      etc) and not things they can't change (e.g. skin colour,
                      body, sexual preferences, etc) Swearing will be tolerated
                      as long as it is not directed towards a specific person or
                      group of people
                    </p>
                  </div>
                </div>
              </div>

              <div className="become-moderator-content">
                <div className="d-flex">
                  <div className="display">
                    <div className="flex-column">
                      <label class="control control--checkbox">
                        <input
                          class="styled-checkbox"
                          id="styled-checkbox-2"
                          type="checkbox"
                          name="value2"
                          value="value2"
                          onChange={e => {
                            setValue2(e.target.checked);
                          }}
                        />
                        <label for="styled-checkbox-2"></label>
                      </label>
                      <p>I Understand</p>
                    </div>
                    <div className="moderator-text">
                      <h1 className="hide">Stick to the facts</h1>
                    </div>
                  </div>
                  <div className=" moderator-text">
                    <h1 className="show">Stick to the facts</h1>
                    <h2>No false or misrepresented information</h2>
                    <p className="sub-text">
                      {" "}
                      Arguments should be primarily fact based rather than
                      personal opinions
                    </p>
                  </div>
                </div>
              </div>

              <div className="become-moderator-content">
                <div className="d-flex">
                  <div className="display">
                    <div className="flex-column">
                      <label class="control control--checkbox">
                        <input
                          class="styled-checkbox"
                          id="styled-checkbox-3"
                          type="checkbox"
                          value="value3"
                          name="value3"
                          onChange={e => {
                            setValue3(e.target.checked);
                          }}
                        />
                        <label for="styled-checkbox-3"></label>
                      </label>
                      <p>I Understand</p>
                    </div>
                    <div className="moderator-text">
                      <h1 className="hide">Once is enough</h1>
                    </div>
                  </div>
                  <div className=" moderator-text">
                    <h1 className="show">Once is enough</h1>
                    <h2>
                      {" "}
                      An argument that is the same as or similar to a previous
                      argument made will not be accepted. Try improving the
                      existing argument instead{" "}
                    </h2>
                    <p className="sub-text">
                      {" "}
                      Don't want the debates to become an endless cycle of
                      saying the same things over and over again
                    </p>
                  </div>
                </div>
              </div>

              <div className="become-moderator-content">
                <div className="d-flex">
                  <div className="display">
                    <div className="flex-column">
                      <label class="control control--checkbox">
                        <input
                          class="styled-checkbox"
                          id="styled-checkbox-4"
                          type="checkbox"
                          value="value4"
                          name="value4"
                          onChange={e => {
                            setValue4(e.target.checked);
                          }}
                        />
                        <label for="styled-checkbox-4"></label>
                      </label>
                      <p>I Understand</p>
                    </div>
                    <div className="moderator-text">
                      <h1 className="hide">Get to the point</h1>
                    </div>
                  </div>
                  <div className=" moderator-text">
                    <h1 className="show">Get to the point</h1>
                    <h2>
                      {" "}
                      Make your arguments concise and as easy to understand as
                      possible.
                    </h2>
                    <p className="sub-text">
                      {" "}
                      Try to avoid flowery language and keep it clear and simple
                    </p>
                  </div>
                </div>
              </div>

              <h4 className="moderator-content">
                Failure to follow these guidelines as a moderator will lead to
                losing those privileges and possibly being banned as a site
                user.
              </h4>
              <div className="btn-submit-div">
                <button
                  className="primary-btn "
                  onClick={handleSubmit}
                  disabled={isCheck}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomeModerator;
