import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./Loader.scss";

export const Spinner = ({color,width,height}) => {
  return (
    <div className="loader">
      <Loader
        type="TailSpin"
        color={color}
        height={height}
        width={width}
        timeout={300000000}
        className="spinner-loader"
      />
    </div>
  );
};
