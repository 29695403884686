import "bootstrap/dist/css/bootstrap.min.css";
import React, {useState, useContext, useEffect} from "react";
import "./Navbar.scss";
import Popup from "../../components/Popup/Popup.js";
import "../../components/RulesPolicies/RulesPolicies";
import Notification from "../../components/Notification/Notification";
import {Link, NavLink} from "react-router-dom";
import {GlobalContext} from "../../components/Context/GlobalContext";
import {postLogout, readNotification} from "../../components/Api/PostApi";
import {withRouter} from "react-router-dom";
import {domain} from "../../components/Api/AxiosInstance";
import {NavDropdown} from "react-bootstrap";
import {ClickAwayListener} from "@material-ui/core";
import {isAuthenticate} from "../../Utils/Utils";
import {useHistory} from "react-router";
import {getUserinfo} from "../../components/Api/GetApi";

function Navbar(props) {
  let history = useHistory();
  const [isOpened, setIsOpened] = useState(false);
  const {setUserId, successMessage, errorMessage, userImage, setUserImage} =
    useContext(GlobalContext);
  const [userData, setUserData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [hideHamburger, setHideHamburger] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const {pathname} = window.location;

  function toggle() {
    setIsOpened(wasOpened => !wasOpened);
  }

  useEffect(() => {
    if (isAuthenticate()) {
      return;
    }
    getUserinfo()
      .then(res => {
        setUserData(res.data);
        setUserId(res.data[0]?.user_front_id);
        setUserImage(res.data[0]?.image);
      })
      .catch(err => {
        sessionStorage.removeItem("_session");
      });
  }, [setUserId, setUserImage, setForgotPassword]);

  const logout = () => {
    postLogout()
      .then(res => {
        if (res.status === 200) {
          localStorage.clear();
          sessionStorage.removeItem("_session");
          props.history.push("/");
          successMessage("Logout Successfully !");
          setTimeout(() => window.location.reload(""), 1000);
        } else {
          errorMessage(res.data.message);
        }
      })
      .catch(err => {
        errorMessage("Unable to logout. Please try again");
      });
  };

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const handleModerator = () => {
    if (isAuthenticate()) {
      setHideHamburger(true);
      setVisible(true);
      return;
    }
    history.push("/profile-moderator");
  };

  const handleNotificationOnClick = notification_count => {
    readNotification({notification_count: notification_count});
  };

  const navDropdownTitle = <i className="bx bx-chevron-down"></i>;
  if (pathname.includes("/verify")) {
    return null;
  }
  return (
    <>
      <div className="nav-bar">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light ">
            <div className="nav-bar-left-side">
              <a className="navbar-brand" href="/">
                <p className="logo">
                  Mass <span className="nav-title">Debaters</span>
                </p>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setHideHamburger(false)}>
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="search-bar d-none">
                <form>
                  <span className="search">
                    <i className="bx bx-search"></i>
                  </span>
                  <input type="text" placeholder="Search ..." />
                </form>
              </div>
            </div>

            <div className="nav-header">
              <div
                className={`collapse navbar-collapse nav-right ${
                  hideHamburger ? "hide" : ""
                }`}
                id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item ">
                    <NavLink
                      exact
                      activeClassName="active"
                      className=" nav-link"
                      onClick={() => setHideHamburger(true)}
                      to="/">
                      Explore
                    </NavLink>
                  </li>

                  <li className="nav-item moderator-nav">
                    <span
                      activeClassName="active"
                      className="nav-link "
                      onClick={() => handleModerator()}>
                      Moderators
                    </span>
                  </li>

                  <li className="nav-item ">
                    <NavLink
                      activeClassName="active"
                      className="nav-link "
                      to="/rules-policies"
                      onClick={() => setHideHamburger(true)}>
                      Rules & Policies
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      activeClassName="active"
                      className="nav-link "
                      onClick={() => setHideHamburger(true)}
                      to="/contact-us">
                      Contact Us
                    </NavLink>
                  </li>
                </ul>

                {isAuthenticate() && (
                  <input
                    type="button"
                    value="Login"
                    className="main-btn"
                    onClick={() => {
                      openModal();
                      setForgotPassword(false);
                    }}
                  />
                )}

                <Popup
                  visible={visible}
                  closeModal={() => closeModal()}
                  setForgotPassword={setForgotPassword}
                  forgotPassword={forgotPassword}
                />
              </div>

              {!isAuthenticate() && (
                <div className="notify-section">
                  <ClickAwayListener onClickAway={() => setIsOpened(false)}>
                    <div
                      onClick={() => {
                        toggle();
                        handleNotificationOnClick(
                          props?.notificationData?.notifications?.length
                        );
                      }}
                      className="bell-icon">
                      <i className="bx bx-bell "></i>
                      {props?.notificationData?.unread_notification !== 0 && (
                        <span className="bell-no">
                          {props?.notificationData?.unread_notification || 0}
                        </span>
                      )}

                      {isOpened && (
                        <div className="header-notification scroll-bar">
                          <Notification
                            type="true"
                            notificationData={props.notificationData}
                            sliceState="false"
                          />
                        </div>
                      )}
                    </div>
                  </ClickAwayListener>

                  <div className="nav-btns">
                    <Link to="/create-debate">
                      <button type="submit" className="primary-btn ">
                        Create
                      </button>
                    </Link>
                  </div>

                  <div className="nav-profile">
                    <Link
                      to={{
                        pathname: "/my-profile",
                        state: {
                          fromNotifications: props.notificationData,
                        },
                      }}>
                      {userImage instanceof File ? (
                        <img
                          src={URL.createObjectURL(userImage)}
                          className="profile-img"
                          alt=""
                          style={{display: userImage ? "block" : "image"}}
                        />
                      ) : (
                        <>
                          {userImage === null ? (
                            <i class="bx bx-user user-icon"></i>
                          ) : (
                            <img
                              src={domain + "/uploads/" + userImage}
                              className="profile-img"
                              alt="img"
                            />
                          )}
                        </>
                      )}
                    </Link>
                    <NavDropdown
                      title={navDropdownTitle}
                      id="basic-nav-dropdown">
                      <div className="log-section">
                        <div className="card-section">
                          <Link to="/my-profile">
                            <div className="flex my-profile">
                              {userImage instanceof File ? (
                                <img
                                  src={URL.createObjectURL(userImage)}
                                  className="profile-img"
                                  alt=""
                                  style={{
                                    display: userImage ? "block" : "image",
                                  }}
                                />
                              ) : (
                                <>
                                  {userImage === null ? (
                                    <i class="bx bx-user log-icon"></i>
                                  ) : (
                                    <img
                                      src={domain + "/uploads/" + userImage}
                                      className="log-img"
                                      alt="img11"
                                    />
                                  )}
                                </>
                              )}

                              <div className="profile-text">
                                <h4 className="title">{userData[0]?.name}</h4>
                                <p className="mid-content">See your profile</p>
                              </div>
                            </div>
                          </Link>
                          <div className="log-out" onClick={logout}>
                            <div className="log-out-icon">
                              <i className="bx bx-log-out "></i>
                            </div>
                            <span className="mid-content">Log Out</span>
                          </div>
                        </div>
                      </div>
                    </NavDropdown>
                  </div>
                </div>
              )}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default withRouter(Navbar);
