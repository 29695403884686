import React, {useState, useEffect, useContext} from "react";
import Modal from "react-awesome-modal";
import "./Editpop.scss";
import * as Yup from "yup";
import {profileEdit} from "../../Api/PostApi";
import {Formik, Form, Field, ErrorMessage} from "formik";
import {getCountryList} from "../../Api/GetApi";
import {domain} from "../../Api/AxiosInstance";
import {GlobalContext} from "../../Context/GlobalContext";
import Loader from "react-loader-spinner";

const Editpopup = props => {
  const [profileImage, setProfileImage] = useState("");
  const {successMessage, setUserImage, errorMessage} =
    useContext(GlobalContext);

  useEffect(() => {
    console.log("profile image", profileImage);
  }, [profileImage]);

  useEffect(() => {
    setProfileImage(domain + "/uploads/" + props.itemObj.image);
  }, [props.itemObj.image]);

  const [countryList, setCountryList] = useState([]);
  useEffect(() => {
    getCountryList()
      .then(res => {
        setCountryList(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div className="edit-popup  ">
      <Modal
        visible={props.visible}
        width="410"
        effect="fadeInUp"
        className="edit-modal"
        onClickAway={() => props.closeModal()}>
        <div className="form-section scroll-bar">
          <Formik
            enableReinitialize
            initialValues={{
              name: props.itemObj.name,
              username: props.itemObj.user_name,
              email: props.itemObj.email,
              dob: props.itemObj.dob,
              country: props.itemObj.country,
              image: props.itemObj.image,
            }}
            validationSchema={Yup.object({
              username: Yup.string().required("Required"),
              name: Yup.string()
                .min(2, "Must be 2 characters or more")
                .required("Required"),

              email: Yup.string()
                .email("Invalid email addresss`")
                .required("Required"),
              country: Yup.string().required("Required"),
            })}
            onSubmit={(values, {setSubmitting}) => {
              setSubmitting(true);
              const postData = new FormData();
              postData.append("name", values.name);
              postData.append("user_name", values.username);
              postData.append("email", values.email);
              postData.append(
                "dob",
                new Date(values.dob).toISOString().slice(0, 10)
              );
              postData.append("image", values.image);
              postData.append("country", values.country);
              props.setIsEdited(false);
              setUserImage(values.image);

              profileEdit(postData)
                .then(res => {
                  successMessage("Profile Successfuly Updated !");
                  props.closeModal();
                  props.setIsEdited(true);
                })
                .catch(error => {
                  errorMessage(error.response.data.message);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}>
            {formik => {
              return (
                <Form>
                  <h3 className="align-itemss">Edit Profile</h3>
                  <div className="contents">
                    <label>Image</label>
                    <div className="avatar-upload">
                      <div className="avatar-edit">
                        <input
                          type="file"
                          id="imageUpload1"
                          accept="image/*"
                          onChange={e => {
                            const image = e.target.files[0];
                            if (image) {
                              const url = URL.createObjectURL(image);
                              setProfileImage(url);
                              formik.setFieldValue("image", image);
                            }
                          }}
                        />
                        <label htmlFor="imageUpload1">
                          <i className="bx bxs-pencil"></i>
                        </label>
                      </div>
                      <div className="avatar-preview">
                        <div
                          id="imagePreview"
                          style={{
                            backgroundImage: `url(${profileImage})`,
                          }}></div>
                      </div>
                    </div>

                    <span className="error">
                      <ErrorMessage name="image" />
                    </span>
                  </div>
                  <div class="contents">
                    <label>Name</label>
                    <Field
                      name="name"
                      type="text"
                      placeholder="Enter your name"
                      className="input-field"
                    />
                    <span className="error">
                      <ErrorMessage name="name" />
                    </span>
                  </div>

                  <div class="contents">
                    <label>Username</label>

                    <Field
                      name="username"
                      type="text"
                      placeholder="Enter your username"
                      className="input-field"
                    />
                    <span className="error">
                      <ErrorMessage name="username" />
                    </span>
                  </div>
                  <div class="contents">
                    <label>Email</label>

                    <Field
                      name="email"
                      type="email"
                      placeholder="Enter your email"
                      className="input-field"
                    />
                    <span className="error">
                      <ErrorMessage name="email" />
                    </span>
                  </div>
                  <div class="contents password-block">
                    <label>DOB</label>

                    <Field
                      name="dob"
                      type="date"
                      className="input-field"
                      placeholder="Enter your dob"
                    />
                    <span className="error">
                      <ErrorMessage name="dob" />
                    </span>
                  </div>
                  <div class="contents">
                    <label htmlFor="country">Choose Country</label>
                    <Field name="country" as="select" className="input-field">
                      {countryList.map((list, index) => {
                        return (
                          <option value={list} key={index}>
                            {list}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                  {/* <h4 className="login-btn">Edit</h4> */}
                  <button
                    type="submit"
                    className=" main-btn login-btn"
                    disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? (
                      <Loader width={20} height={20} color="#FFFFFF" />
                    ) : (
                      <span>Update</span>
                    )}
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default Editpopup;
