import React from "react";
import "./notFoundPage.scss";

/**@type {React.FC<{title:string,image:string}>} */
const NotFoundPage = ({image, title}) => {
  return (
    <div className="not-found-img text-center">
      <div className="wrapper">
        <img src={require(`../../assets/images/${image}`).default} alt="img" />
        <p className="img-highlight">No {title} </p>
        <p className="img-title">There is no {title} at the moment.</p>
      </div>
    </div>
  );
};
export default NotFoundPage;
