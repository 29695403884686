import React, {useState, useEffect} from "react";
import "../TabFor/TabFor.scss";
import {getDebatesList} from "../Api/GetApi";
import {Link} from "@material-ui/core";

function TabDebate() {
  const [debatesList, setDebatesList] = useState([]);
  const [sliceCount, setSliceCount] = useState(5);
  const [showButton,setShowButton] = useState(true);

  useEffect(() => {
    getDebatesList().then(res => {
      setDebatesList(res.data);
    });
  }, []);

  return (
    <div className="for-section">
      {debatesList?.slice(0, sliceCount).map((item, index) => {
        return (
          <div className="for-content" key={index}>
            <div>
              <p className="sub-text">{item.title}</p>
            </div>
            <div className="d-flex">
              <button className="popular-btn btn-for">
                <span>
                  <i class="bx bx-like"></i>
                </span>
                For{" "}
                <span>{item.for_count === null ? "0" : item.for_count}</span>{" "}
              </button>

              <button className="popular-btn btn-against">
                <span className="btn-points">
                  <i class="bx bx-dislike"></i>
                </span>
                Against{" "}
                <span className="btn-points">
                  {item.against_count === null ? "0" : item.against_count}
                </span>
              </button>

              {/* <button className="popular-btn btn-">
                                <span className="transform btn-points">
                                    <i class="bx bx-like"></i>
                                </span>
                                 <span className="btn-points">13</span>
                            </button> */}
            </div>
          </div>
        );
      })}

      {/* <div className="for-content">
                <div>
                    <p className="sub-text">All drugs should be legalized.</p>
                </div>
                <div className="d-flex">
                    <button className="popular-btn btn-for">
                        <span>
                            <i class="bx bx-like"></i>
                        </span>
                        For <span>13</span>{" "}
                    </button>

                    <button className="popular-btn btn-against">
                        <span className="btn-points">
                            <i class="bx bx-dislike"></i>
                        </span>
                        Against <span className="btn-points">13</span>
                    </button>

                    <button className="popular-btn btn-">
                        <span className="transform btn-points">
                            <i class="bx bx-like"></i>
                        </span>
                         <span className="btn-points">13</span>
                    </button>
                </div>
            </div>

            <div className="for-content">
                <div>
                    <p className="sub-text">All drugs should be legalized.</p>
                </div>
                <div className="d-flex">
                    <button className="popular-btn btn-for">
                        <span>
                            <i class="bx bx-like"></i>
                        </span>
                        For <span>13</span>{" "}
                    </button>

                    <button className="popular-btn btn-against">
                        <span className="btn-points">
                            <i class="bx bx-dislike"></i>
                        </span>
                        Against <span className="btn-points">13</span>
                    </button>

                    <button className="popular-btn btn-">
                        <span className="transform btn-points">
                            <i class="bx bx-like"></i>
                        </span>
                         <span className="btn-points">13</span>
                    </button>
                </div>
            </div>

            <div className="for-content">
                <div>
                    <p className="sub-text">All drugs should be legalized.</p>
                </div>
                <div className="d-flex">
                    <button className="popular-btn btn-for">
                        <span>
                            <i class="bx bx-like"></i>
                        </span>
                        For <span>13</span>{" "}
                    </button>

                    <button className="popular-btn btn-against">
                        <span className="btn-points">
                            <i class="bx bx-dislike"></i>
                        </span>
                        Against <span className="btn-points">13</span>
                    </button>

                    <button className="popular-btn btn-">
                        <span className="transform btn-points">
                            <i class="bx bx-like"></i>
                        </span>
                         <span className="btn-points">13</span>
                    </button>
                </div>
            </div>

            <div className="for-content">
                <div>
                    <p className="sub-text">All drugs should be legalized.</p>
                </div>
                <div className="d-flex">
                    <button className="popular-btn btn-for">
                        <span>
                            <i class="bx bx-like"></i>
                        </span>
                        For <span>13</span>{" "}
                    </button>

                    <button className="popular-btn btn-against">
                        <span className="btn-points">
                            <i class="bx bx-dislike"></i>
                        </span>
                        Against <span className="btn-points">13</span>
                    </button>

                    <button className="popular-btn btn-">
                        <span className="transform btn-points">
                            <i class="bx bx-like"></i>
                        </span>
                         <span className="btn-points">13</span>
                    </button>
                </div>
            </div> */}
      {debatesList.length > 5 && showButton === true? (
        <Link to="/my-profile">
          <button
            className="btn- btn-see"
            onClick={() => {setSliceCount(debatesList.length)
            setShowButton(false)
            }}>
            See More
          </button>
        </Link>
      ) : (
        ""
      )}
    </div>
  );
}

export default TabDebate;
