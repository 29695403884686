import React from "react";

/**@type {React.FC<{
 * interAction:""|"liked"|"disliked",
 * onClick:React.MouseEventHandler<HTMLElement>,
 * count:number
 * }>} */
const LikeButton = ({interAction = "", onClick, count = 0}) => {
  return (
    <p
      className={[
        "view-icon",
        "link-color",
        interAction === "liked" && "active-like",
      ].join(" ")}>
      <i className="bx bxs-like" onClick={onClick}></i>
      <span className="number">{count}</span>
    </p>
  );
};

export default LikeButton;
