import React, {useEffect, useState} from "react";
import "./explore.scss";
import {useExplore} from "../Api/GetApi";
import {Link} from "react-router-dom";
import {domain} from "../Api/AxiosInstance";

function Explore(props) {
  const explore = useExplore();
  const [sliceCount, setSliceCount] = useState();
  const [sliceState, setSliceState] = useState(false);
  useEffect(() => {
    if (sliceState === false) {
      setSliceCount(8);
    } else {
      const totalLength = explore.length;
      setSliceCount(totalLength);
    }
  }, [explore, sliceState]);

  return (
    <div className="explore-section section-gap">
      <div className="container">
        <div className="head-wrapper">
          <p className="main-title">Explore</p>
        </div>

        <div className="row">
          {explore?.slice(0, sliceCount).map((item, index) => (
            <Link
              to={{
                pathname: `/sub-category/${item.slug}`,
                state: {
                  category: item.category_name,
                },
              }}
              className="col-lg-3 col-md-3 col-sm-4 child">
              <div key={index}>
                <div className="category-content">
                  <div className="breadcrumb-overlay"></div>
                  <p className="categories">{item.category_name}</p>
                  <img
                    src={domain + item.image.url}
                    alt="img"
                    className="category-img"
                  />
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="see-more mg-top">
          <button
            className="popular-btn "
            type="submit"
            onClick={() => {
              setSliceState(!sliceState);
            }}>
            {sliceState === false ? "See More" : "See Less"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Explore;
