import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import {domain} from "../Api/AxiosInstance";
import parse from "html-react-parser";
import TimeAgo from "timeago-react";
import ShowMoreText from "react-show-more-text";
import {useParams} from "react-router";
import NotFoundPage from "../notFoundPage/notFoundPage";
import "./Debates.scss";
import {
  debateDisLike,
  debateLike,
  debateUndecide,
} from "../../Helper/DebateHelper";

function Debates(props) {
  const [type, setType] = useState(null);
  const [sliceCount, setSliceCount] = useState(5);
  const {id: categoryId} = useParams();
  console.log(categoryId);
  const [disableButtons, setDisableButton] = useState(false);

  useEffect(() => {
    if (props.type === 0) {
      setType("popular");
      return;
    }
    if (props.type === 1) {
      setType("all");
      return;
    }
    if (props.type === 2) {
      setType("new");
      return;
    }
    if (props?.sliceState === "false") {
      setSliceCount(props?.item?.length);
    }
  }, [props?.item?.length, props?.sliceState, props.type]);

  const stancePage = slug => {
    props.history.push(`/stance/${slug}`);
  };

  return (
    <>
      {props?.item?.length < 1 ? (
        <NotFoundPage title="new Debates" image="nodata.svg" />
      ) : (
        <>
          {props?.item?.slice(0, sliceCount).map((popular, index) => {
            return (
              <>
                {/* <DebateCard popular={popular} /> */}
                <div className="card-section" key={index}>
                  <div className="row">
                    <div className="col-lg-9 col-md-9 col-sm-8">
                      <div className="pr-15">
                        <div onClick={() => stancePage(popular.slug)}>
                          <div className="space-between">
                            <button
                              type="submit"
                              name="health"
                              style={{
                                color: popular.category_color,
                                background: popular.color_opacity,
                              }}
                              className="popular-btn category-btn">
                              {popular.category_name}
                            </button>

                            <p className="time gray-text">
                              <TimeAgo datetime={popular.created_at} />
                            </p>
                          </div>
                          <p className="sub-text">{popular.title}</p>
                          <p className="gray-text">
                            <ShowMoreText
                              lines={5}
                              more="Show more"
                              less="Show less"
                              className="content-css"
                              anchorClass="my-anchor-css-class"
                              expanded={false}
                              truncatedEndingComponent={"... "}>
                              {parse(`${popular.opinion}`)}
                            </ShowMoreText>
                          </p>
                        </div>
                        <div className="show-block">
                          <div className="agree">
                            <button
                              disabled={disableButtons}
                              type="submit"
                              name="health"
                              className={
                                popular.like_event
                                  ? "btn-for-active popular-btn"
                                  : "popular-btn btn-for"
                              }
                              onClick={() => {
                                setDisableButton(true);
                                debateLike(
                                  popular.debate_id,
                                  popular.user_front_id,
                                  () => {
                                    props.refetch();
                                    setDisableButton(false);
                                  }
                                );
                              }}>
                              <span className="like-icon cw like">
                                <i class="bx bx-like"></i>
                              </span>
                              For
                              <span className="number cw">
                                {popular.like_count || 0}
                              </span>
                            </button>
                            <button
                              disabled={disableButtons}
                              type="submit"
                              name="Against"
                              className={
                                popular.dislike_event
                                  ? "btn-against-active popular-btn"
                                  : "popular-btn btn-against"
                              }
                              onClick={() => {
                                setDisableButton(true);
                                debateDisLike(
                                  popular.debate_id,
                                  popular.user_front_id,
                                  () => {
                                    props.refetch();
                                    setDisableButton(false);
                                  }
                                );
                              }}>
                              <span className="against-icon cw">
                                <i class="bx bx-dislike"></i>
                              </span>
                              Against
                              <span className="number cw">
                                {popular.dislike_count || 0}
                              </span>
                            </button>
                            <button
                              disabled={disableButtons}
                              type="submit"
                              name="Undecided"
                              className={
                                popular.undecided_event
                                  ? "btn-undecided-active popular-btn"
                                  : "popular-btn btn-undecided"
                              }
                              onClick={() => {
                                setDisableButton(true);
                                debateUndecide(
                                  popular.debate_id,
                                  popular.user_front_id,
                                  () => {
                                    props.refetch();
                                    setDisableButton(false);
                                  }
                                );
                              }}>
                              <span className="like-icon cw unlike">
                                <i class="bx bx-like transform"></i>
                              </span>
                              Undecided
                              <span className="number cw">
                                {popular.undecided_count || 0}
                              </span>
                            </button>
                          </div>
                          <hr />
                          <div className="views" title="Total Commenter">
                            <p className="view-icon cw">
                              <i class="bx bx-comment"></i>
                              <span className="number">
                                {popular.total_commenters}
                              </span>
                            </p>

                            <p className="view-icon cw" title="Total Views">
                              <i class="bx bx-street-view"></i>
                              <span className="number">{popular.views}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-4">
                      {popular.image_url === null ? (
                        // <div
                        //   style={{
                        //     color: popular.category_color,
                        //   }}
                        //   className="place-holder popular-img">
                        //   {popular.category_name}
                        // </div>
                        <>
                          <img
                            className="popular-img"
                            src={domain + "/uploads/" + popular?.category_image}
                            alt="img"
                          />
                        </>
                      ) : (
                        <img
                          className="popular-img"
                          src={domain + "/uploads/" + popular.image_url}
                          alt="img"
                        />
                        // <p>dfghj</p>
                      )}
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 hide-block">
                      <div className="agree">
                        <button
                          type="submit"
                          name="health"
                          className="popular-btn btn-for undecided"
                          onClick={() =>
                            debateLike(
                              popular.debate_id,
                              popular.user_front_id,
                              () => {
                                props.refetch();
                              }
                            )
                          }>
                          <span className="like-icon cw">
                            <i class="bx bx-like"></i>
                          </span>
                          For
                          <span className="number cw">
                            {popular.like_count || 0}
                          </span>
                        </button>
                        <button
                          type="submit"
                          name="Against"
                          className="popular-btn btn-against"
                          onClick={() =>
                            debateDisLike(
                              popular.debate_id,
                              popular.user_front_id,
                              () => {
                                props.refetch();
                              }
                            )
                          }>
                          <span className="like-icon cw">
                            <i class="bx bx-dislike"></i>
                          </span>
                          Against
                          <span className="number cw">
                            {popular.dislike_count || 0}
                          </span>
                        </button>
                        <button
                          type="submit"
                          name="Undecide"
                          onClick={() =>
                            debateUndecide(
                              popular.debate_id,
                              popular.user_front_id,
                              () => {
                                props.refetch();
                              }
                            )
                          }
                          className="popular-btn btn-undecided">
                          <span className="like-icon cw">
                            <i class="bx bx-like transform"></i>
                          </span>

                          <span className="number cw">
                            {popular.undecided_count || 0}
                          </span>
                        </button>
                      </div>
                      <hr />
                      <div className="views">
                        <p className="view-icon cw" title="Total Commenters">
                          <i class="bx bx-comment"></i>
                          <span className="number">
                            {popular.total_commenters}
                          </span>
                        </p>

                        <p className="view-icon cw" title="Total Views">
                          <i class="bx bx-street-view"></i>
                          <span className="number">{popular.views}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          <div className="see-more">
            <Link to={`/debates/${type}`}>
              {categoryId ? (
                ""
              ) : (
                <button type="submit" name="seemore" className="popular-btn">
                  See More
                </button>
              )}
            </Link>
          </div>
        </>
      )}
    </>
  );
}
export default withRouter(Debates);
