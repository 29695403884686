import React from "react";
import "../../components/addStance/addStance";
import "./popular.scss";
import {TabComponent} from "../TabComponent/TabComponent";

function Popular(props) {
  const title = ["Popular", "all ", "new "];

  return (
    <>
      <div className="popular-section section-gap">
        <div className="container">
          <TabComponent
            type="debate"
            title={title}
            debateData={props?.debateData}
            changeTab={props.changeTab}
            refetch={props.refetch}
          />
        </div>
      </div>
    </>
  );
}

export default Popular;
