import React, {useState} from "react";
import "./addStance.scss";
import {domain} from "../Api/AxiosInstance";
import {
  debateUndecide,
  debateLike,
  debateDisLike,
} from "../../Helper/DebateHelper";
// import {useHistory} from "react-router-dom";
import MetaConfigure from "../Helmet/Helmet";

const AddStance = props => {
  const [activelike, setActivelike] = useState(false);
  const [activeDislike, setActiveDislike] = useState(false);
  const [activeUndecide, setActiveUndecide] = useState(false);
  const [disableButtons, setDisableButton] = useState(false);

  return (
    <div className="stance-section">
      {props.data.map(item => {
        return (
          <div
            className="banner "
            style={{
              backgroundImage: `url("${
                domain + "/uploads/" + item.category_image
              }")`,
            }}>
            <MetaConfigure title={`${item.title} - Mass Debaters`} />

            <div className="banner-overlay">
              <div className="banner-section">
                <h1 className="banner-text">{item.title}</h1>
                <div className="agree">
                  <button
                    disabled={disableButtons}
                    type="submit"
                    name="health"
                    className={
                      activelike || props?.data[0]?.like_event
                        ? "btn-for-active popular-btn"
                        : "popular-btn btn-for"
                    }
                    onClick={() => {
                      setDisableButton(true);
                      debateLike(props.id, item.user_front_id, () => {
                        props.setLikeDislike(!props.likeDislike);
                        setActivelike(prevActiveLike => !prevActiveLike);
                        setActiveDislike(false);
                        setActiveUndecide(false);
                        setDisableButton(false);
                      });
                    }}>
                    <span className="like-icon cw ">
                      <i class="bx bx-like"></i>
                    </span>
                    For
                    <span className="number cw">{item.like_count || 0}</span>
                  </button>

                  <button
                    disabled={disableButtons}
                    type="submit"
                    name="Against"
                    className={
                      activeDislike || props?.data[0]?.dislike_event
                        ? "btn-against-active popular-btn"
                        : "popular-btn btn-against"
                    }
                    onClick={() => {
                      setDisableButton(true);
                      debateDisLike(props.id, item.user_front_id, () => {
                        props.setLikeDislike(!props.likeDislike);
                        setActiveDislike(
                          prevActiveDisLike => !prevActiveDisLike
                        );
                        setActivelike(false);
                        setActiveUndecide(false);
                        setDisableButton(false);
                      });
                    }}>
                    <span className="like-icon cw against-icon">
                      <i class="bx bx-dislike"></i>
                    </span>
                    Against
                    <span className="number cw">{item.dislike_count || 0}</span>
                  </button>
                  <button
                    disabled={disableButtons}
                    type="submit"
                    name="Undecided"
                    className={
                      activeUndecide || props?.data[0]?.undecide_event
                        ? "btn-undecided-active popular-btn"
                        : "popular-btn btn-undecided"
                    }
                    onClick={() => {
                      setDisableButton(true);
                      debateUndecide(props.id, item.user_front_id, () => {
                        props.setLikeDislike(!props.likeDislike);
                        setActiveUndecide(
                          prevActiveUndecide => !prevActiveUndecide
                        );
                        setActivelike(false);
                        setActiveDislike(false);
                        setDisableButton(false);
                      });
                    }}>
                    <span className="like-icon cw unlike">
                      <i class="bx bx-like transform"></i>
                    </span>
                    Undecided
                    <span className="number cw">
                      {item.undecided_count || 0}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AddStance;
