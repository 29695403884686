import React, {useState, useEffect} from "react";
import "../../components/popular/popular.scss";
import Debates from "../Debates/Debates";
import {useParams} from "react-router";
import {useDebates} from "../Api/GetApi";
import Explore from "../explore/explore";

function Seemore() {
  let {type: paramsType} = useParams();
  const [type, setType] = useState(null);

  const {data: debateData, refetch: refetchDebateData} = useDebates();
  useEffect(() => {
    refetchDebateData();
    if (paramsType === "popular") {
      setType(0);
      return;
    }
    if (paramsType === "all") {
      setType(1);
      return;
    }
    if (paramsType === "new") {
      setType(2);
      return;
    }
  }, [paramsType, refetchDebateData]);
  if (!debateData) return null;
  return (
    <div className="see-more-btn popular-section ">
      <div className="container">
        <Explore />
        <Debates
          item={debateData[type]}
          sliceState="false"
          refetch={refetchDebateData}
        />
      </div>
    </div>
  );
}
export default Seemore;
