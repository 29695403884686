// import Axios from "axios";
import Axios from "../Api/AxiosInstance";

export const Login = data => {
  return Axios.post("user_front_token", data);
};

export const Signup = () => {
  return Axios.post("user/create");
};

export const Contactus = data => {
  return Axios.post("contact_us", data);
};

export const BecomeAModerator = data => {
  return Axios.post("notify_become_a_moderator", data);
};

export const SignUpForm = data => {
  return Axios.post("user/create", data);
};

export const createDebate = data => {
  return Axios.post("create_debate", data);
};

export const profileEdit = data => {
  return Axios.post(`user/edit`, data);
};

export const Comment = data => {
  return Axios.post("comment", data);
};

export const Like = data => {
  return Axios.post("debate/like", data);
};
export const disLike = data => {
  return Axios.post("debate/dislike", data);
};

export const undecide = data => {
  return Axios.post("debate/undecide", data);
};

export const commentLike = data => {
  return Axios.post("comment/like", data);
};

export const commentDislike = data => {
  return Axios.post("comment/dislike", data);
};

export const postLogout = () => {
  return Axios.post("user_front_logout");
};

export const postSubComment = data => {
  return Axios.post("comment/create_sub_comment/", data);
};

export const postFacebookLogin = data => {
  return Axios.post("fb_login", data);
};

export const postGoogleLogin = data => {
  return Axios.post("google_login", data);
};

export const postIPAddress = data => {
  return Axios.post("user/visit/", data);
};

export const postEditComment = (id, comment) => {
  return Axios.post(`sub_comment/edit/${id}`, {comment: comment});
};
export const postDeleteComment = id => {
  return Axios.delete(`sub_comment/delete/${id}`);
};

//PW

export const postPasswordForget = data => {
  return Axios.post("password/forgot", data);
};

export const postPasswordReset = postData => {
  return Axios.post("password/reset/new", postData);
};

export const readNotification = postData => {
  return Axios.post("read_notification", postData);
};
