import React from "react";
import "./subCategory.scss";
import Popular from "../popular/popular";
import {useExploreNavigation} from "../Api/GetApi";
import {domain} from "../Api/AxiosInstance";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import MetaConfigure from "../Helmet/Helmet";

const SubCategory = () => {
  const {slug: categoryId} = useParams();

  const history = useHistory();

  const {data: exploreNavigation, refetch: refetchExploreNavigation} =
    useExploreNavigation(categoryId);

  const getSubcategory = categoryId => {
    history.push(`/sub-category/${categoryId}`);
  };

  return (
    <div className="sub-category-section">
      {exploreNavigation?.catgegory_details?.map((item, index) => {
        return (
          <div>
            <div
              key={index}
              className="banner"
              style={{
                backgroundImage: `url("${domain + "/uploads/" + item.image}")`,
              }}>
              <MetaConfigure title={`${item.category_name} - Mass Debaters`} />

              <div className="banner-overlay">
                <div className="banner-section">
                  <h1 className="banner-text">{item.category_name}</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="sub-sections">
                {item?.sub_category_details?.[0]?.map((subItem, index) => {
                  return (
                    <div
                      className="border-img"
                      key={index}
                      onClick={() => {
                        getSubcategory(subItem?.slug);
                      }}>
                      <img
                        src={domain + "/uploads/" + subItem.image}
                        alt="img"
                      />
                      <p className="content">{subItem.category_name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      <Popular
        debateData={exploreNavigation?.debates}
        changeTab={true}
        refetch={refetchExploreNavigation}
      />
    </div>
  );
};

export default SubCategory;
