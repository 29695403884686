import React, {useEffect, useState} from "react";
import "./Notification.scss";
import {domain} from "../Api/AxiosInstance";
import TimeAgo from "timeago-react";
import {Link} from "react-router-dom";
import NotFoundPage from "../notFoundPage/notFoundPage";

const Notification = props => {
  const [sliceCount, setSliceCount] = useState(10);
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    setNotificationData(props?.notificationData?.notifications);
    if (props?.sliceState === "true") {
      setSliceCount(props?.length);
    }
  }, [
    props?.length,
    props?.sliceState,
    props?.notificationData?.notifications,
  ]);

  return (
    <div className="section-gap">
      <div className="notification scroll-bar">
        <div className="space-between">
          <h4 className="title">Notifications</h4>

          {props?.type === "true" ? (
            <a href="/notification">
              <p className="view content">See all</p>
            </a>
          ) : (
            // <p className="view content">Mark all as read</p>
            ""
          )}
        </div>

        {notificationData?.length < 1 ? (
          <NotFoundPage title={"notification"} image="notification.png" />
        ) : (
          <div className="pt-15">
            {notificationData?.slice(0, sliceCount)?.map((item, index) => {
              return (
                <>
                  <Link to={`/stance/${item.debate_id}`}>
                    <div className="card notification-card">
                      <div className="card-body notifications">
                        <div className="flex">
                          <div className="title-img">
                            <div className="picture">
                              {item.admin_image !== null ? (
                                <img
                                  className="image-uploads"
                                  src={domain + "/uploads/" + item.admin_image}
                                  alt="images"
                                />
                              ) : (
                                [
                                  item.image !== null ? (
                                    <img
                                      className="image-uploads"
                                      src={domain + "/uploads/" + item.image}
                                      alt="images"
                                    />
                                  ) : (
                                    <span className="image-uploads avatar">
                                      A
                                    </span>
                                  ),
                                ]
                              )}
                              <span className="agree-icon">
                                <div className="b-circle">
                                  {item.notification_type === "like" ? (
                                    <i class="bx bxs-like"></i>
                                  ) : item.notification_type === "comment" ? (
                                    <i class="bx bxs-comment"></i>
                                  ) : item.notification_type === "accept" ? (
                                    <i class="bx bx-check tick"></i>
                                  ) : item.notification_type === "reject" ? (
                                    <i class="bx bx-x"></i>
                                  ) : item.notification_type === "dislike" ? (
                                    <i class="bx bxs-dislike"></i>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </span>
                            </div>
                          </div>

                          <div className="notification-block">
                            <p className="description">
                              <span>{item?.description}</span>:
                              <span className="bold title-color">
                                {item?.title === "null" ? " " : item?.title}
                              </span>
                            </p>
                            <p className="small-content time  time-hour pt-6">
                              <TimeAgo datetime={item.created_at} />
                            </p>
                          </div>

                          <div
                            className="see-more section-gap"
                            key={index}></div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
export default Notification;
