import React, {useState, useEffect, useContext} from "react";
import "./MyProfile.scss";
import "../TabFor/TabFor.scss";

import cake from "./cake .png";
import medal from "./medal.png";
import SimpleTabs from "../tabpanels/Tabpanels";
import group from "./Group .png";
import EditPopUp from "../../components/Popup/Editform/Editpop";
import {getProfile} from "../Api/GetApi";
import {domain} from "../Api/AxiosInstance";
import {GlobalContext} from "../Context/GlobalContext";
import MetaConfigure from "../Helmet/Helmet";

function MyProfile(props) {
  const [seeProfile, setSeeProfile] = useState([]);
  const [visible, setVisible] = useState(false);
  const [itemObj, setItemObj] = useState(0);
  const [isEdited, setIsEdited] = useState(false);
  const {userImage} = useContext(GlobalContext);

  useEffect(() => {
    getProfile().then(res => {
      setSeeProfile(res.data || []);
    });
  }, [isEdited]);

  const openModal = id => {
    setVisible(true);
    setItemObj(seeProfile.find(f => f.user_front_id === id));
  };

  const closeModal = () => {
    setVisible(false);
  };
  return (
    <div>
      <div className="section-gap myprofile-section">
        <div className="container">
          <p className="main-title black"> My Profile</p>
          <div className="card-section myprofile-content">
            {seeProfile?.map((item, index) => {
              return (
                <div className="row" key={index}>
                  <MetaConfigure title={`${item.name} - Mass Debaters`} />

                  <div className="col-md-4 col-lg-4 profile-info">
                    <div className="d-flex">
                      <div className="img-content">
                        {userImage instanceof File ? (
                          <img
                            src={URL.createObjectURL(userImage)}
                            alt="img"
                            className="user-img"
                          />
                        ) : (
                          <>
                            {userImage === null ? (
                              <i class="bx bx-user user-icon"></i>
                            ) : (
                              <img
                                src={domain + "/uploads/" + userImage}
                                alt="img"
                                className="user-img"
                              />
                            )}
                          </>
                        )}

                        {/* <img src={group2} className="icon-img" /> */}
                      </div>
                      <div className="info-content">
                        <h3 className="profile-text">
                          {item.name}
                          {item.points > 10 ? (
                            <span className="debate-text">
                              {" "}
                              <img src={group} alt="img" />
                              Top Debator
                            </span>
                          ) : (
                            <></>
                          )}
                        </h3>
                        <p className="profile-sub-text">{item.email}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-6">
                    <div className="d-flex sub-content">
                      <img src={medal} alt="img" />
                      <div className="profile-points">
                        <p className="profile-sub-text profile-text">
                          Available Points
                        </p>
                        <h3 className="birth-date">
                          {item.points === null ? "0" : item.points}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-5 col-sm-6">
                    <div className="space-between">
                      <div className="d-flex sub-content">
                        <img src={cake} alt="img" />
                        <div className="profile-points">
                          <p className="profile-sub-text">Cake Day</p>
                          <h3 className="birth-date">{item.dob}</h3>
                        </div>
                      </div>
                      <button
                        className="primary-btn"
                        onClick={() => {
                          openModal(item.user_front_id);
                        }}>
                        Edit Profile
                      </button>
                    </div>
                    <EditPopUp
                      data={seeProfile}
                      visible={visible}
                      closeModal={() => closeModal()}
                      itemObj={itemObj}
                      setIsEdited={setIsEdited}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="card-section">
            <SimpleTabs />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
