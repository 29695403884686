//@ts-check
import React, {useState, useContext, useEffect} from "react";
import "./textEditor.scss";
import {Comment} from "../Api/PostApi";
import {GlobalContext} from "../Context/GlobalContext";
import {Formik, Form, Field, ErrorMessage} from "formik";
import Popup from "../Popup/Popup";
import {getExplore} from "../Api/GetApi";
import {useHistory} from "react-router-dom";
// import {CkEditor} from "../ckEditor/ckEditor";
import {isAuthenticate} from "../../Utils/Utils";

import * as Yup from "yup";

const TextEditor = props => {
  const {successMessage, errorMessage} = useContext(GlobalContext);
  // const [visible, setVisible] = useState(false);
  const [loginVisible, setloginVisible] = useState(false);
  const [category, setCategory] = useState([]);
  const history = useHistory();
  const [showLogin, setShowLogin] = useState(false);

  const [noOfElements, setnoOfElements] = useState(10);
  const slice = category.slice(0, noOfElements);
  const loadMore = () => {
    setnoOfElements(noOfElements + 4);
  };
  const loadLess = () => {
    setnoOfElements(noOfElements - 4);
  };
  // const [smallPopUp, setSmallPopUp] = useState(false);

  // const closeModal = () => {
  //   setVisible(false);
  // };

  const closeloginModal = () => {
    setloginVisible(false);
  };

  useEffect(() => {
    getExplore().then(res => {
      setCategory(res.data);
    });
  }, []);

  return (
    <div className="editor-section section-gap">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-md-12 col-sm-12">
            <div className="card-section card-shadow">
              <div className="back-color">
                <h3 className="">Submit your argument</h3>

                {isAuthenticate() ? (
                  <p className="content editor-text">
                    Please Login to submit!
                    <span
                      className="font-500"
                      onClick={() => {
                        setloginVisible(true);
                        setShowLogin(true);
                      }}>
                      Click Here!
                    </span>
                  </p>
                ) : (
                  ""
                )}
              </div>
              {showLogin && (
                <Popup
                  visible={loginVisible}
                  closeModal={() => closeloginModal()}
                />
              )}
              <Formik
                initialValues={{
                  comment: "",
                  title: "",
                  debate_id: "",
                  name: "",
                  comment_type:
                    props.stance === "for"
                      ? "1"
                      : props.stance === "against"
                      ? "2"
                      : "",
                }}
                validationSchema={Yup.object({
                  title: Yup.string()
                    .min(2, "Must be 2 characters or more")
                    .required("Required!!"),

                  comment: Yup.string()
                    .min(2, "Must be 2 characters or more")
                    .required("Please write the comment"),

                  comment_type: Yup.string().required("Required!!"),
                })}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                  const postData = {
                    debate_id: props.data[0].debate_id,
                    title: values.title,
                    comment_type_id: values.comment_type,
                    comment: values.comment,
                  };
                  if (isAuthenticate()) {
                    errorMessage("Please login first to continue !");
                    setSubmitting(false);
                  } else {
                    Comment(postData).then(res => {
                      if (res.status === 200) {
                        successMessage("Commented Successfully !");
                        resetForm();
                      } else {
                        errorMessage(res.data.message);
                      }
                      setSubmitting(false);
                    });
                  }
                }}>
                {formik => {
                  return (
                    <Form>
                      <div className="ck-editor-text">
                        <div>
                          <div className="row">
                            <div className="col-md-2 col-lg-2 col-sm-12">
                              <p className="content font-500 stance mb-1">
                                Stance
                              </p>

                              <Field
                                as="select"
                                name="comment_type"
                                className="input-field">
                                <option value="" selected disabled>
                                  Select
                                </option>
                                <option value="1">For</option>
                                <option value="2">Against</option>
                              </Field>
                              <span className="errors">
                                <ErrorMessage name="comment_type" />
                              </span>
                            </div>

                            <div className="col-md-10 col-lg-10 col-sm-12">
                              <p className="content font-500 mb-1">
                                Key Argument
                              </p>
                              <div className="flex ">
                                {/* <form> */}
                                <Field
                                  type="text"
                                  name="title"
                                  placeholder="Write key arguments here"
                                  className="input-field"
                                />
                                <span className="errors">
                                  <ErrorMessage name="title" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="ckeditor-wrapper">
                            <Field
                              as="textarea"
                              name="comment"
                              rows="6"
                              placeholder="Write Statement"
                              className="input-field"
                            />
                            {/* <CkEditor /> */}
                            <span className="error">
                              <ErrorMessage name="comment" />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="close-btns">
                        {!isAuthenticate() ? (
                          <button
                            type="submit"
                            className="primary-btn comment-submit"
                            disabled={formik.isSubmitting}>
                            Submit
                          </button>
                        ) : (
                          ""
                        )}

                        {/* {setVisible ? (
                          <ModalPopUp
                            visible={visible}
                            closeModal={() => closeModal()}
                            handleSubmit={formik.handleSubmit}
                            smallPopUp={smallPopUp}
                          />
                        ) : (
                          <></>
                        )} */}
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
          <div className="col-lg-2 col-md-12 col-sm-12">
            <h3 className="explore">Explore</h3>
            <div className="flex flex-btns">
              {category.length > 0 &&
                slice.map((item, index) => {
                  return (
                    <button
                      key={index}
                      type="submit"
                      name="politics"
                      className="popular-btn gray-text"
                      onClick={() => {
                        history.push(`/sub-category/${item.slug}`);
                      }}>
                      {item.category_name}
                    </button>
                  );
                })}
              {noOfElements >= category.length ? (
                ""
              ) : (
                <span className="btn" onClick={() => loadMore()}>
                  Load more ....
                </span>
              )}

              {noOfElements > 10 && (
                <span className="btn " onClick={() => loadLess()}>
                  Load less ....
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextEditor;
