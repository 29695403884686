import React, {createContext, useState} from "react";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GlobalContext = createContext({
  successMessage: str => {},
  errorMessage: str => {},
  userId: "",
  userImage: "",
  userName: "",
  setUserId: () => {},
  setUserImage: () => {},
  setUserName: str => {},
  loader: bool => {},
});

const GlobalProvider = ({children}) => {
  const [userId, setUserId] = useState("");
  const [userImage, setUserImage] = useState("");
  const [userName, setUserName] = useState("");
  const [showLoader, setShowLoader] = useState(true);

  const successMessage = str => {
    toast.success(str);
  };

  const errorMessage = str => {
    toast.error(str);
  };

  const loader = bool => {
    setShowLoader(bool);
  };

  return (
    <GlobalContext.Provider
      value={{
        successMessage,
        errorMessage,
        userId,
        userImage,
        userName,
        setUserId,
        setUserImage,
        setUserName,
        loader,
        showLoader,
        setShowLoader,
      }}>
      {/* <ToastContainer /> */}
      {children}
    </GlobalContext.Provider>
  );
};

const GloabalConsumer = GlobalContext.Consumer;
export {GlobalContext, GlobalProvider, GloabalConsumer};
