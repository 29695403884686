import React, {useState, useEffect} from "react";
import "../TabFor/TabFor.scss";
import {getTabAgainst} from "../Api/GetApi";
import {Link} from "@material-ui/core";

function TabAgainst(props) {
  const [tabAgainst, setTabAgainst] = useState([]);
  const [sliceCount, setSliceCount] = useState(5);
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    getTabAgainst().then(res => {
      setTabAgainst(res.data);
    });
  }, []);

  return (
    <div className="for-section">
      {tabAgainst?.slice(0, sliceCount).map((item, index) => {
        return (
          <>
            <div className="for-content" key={index}>
              <div className="for-title">
                <p className="sub-text">{item.title}</p>
              </div>
              <div className="d-flex">
                <button className="popular-btn btn-for">
                  <span className="like-icon cw">
                    <i class="bx bx-like icon-likes"></i>
                  </span>
                  For{" "}
                  <span className="number cw">
                    {item.for_count === null ? "0" : item.for_count}
                  </span>{" "}
                </button>

                <button
                  className={`popular-btn
                 ${props.index === 2 ? "red-bg" : ""}`}>
                  <span className="btn-points">
                    <i class="bx bx-dislike icon-dislikes"></i>
                  </span>
                  Against{" "}
                  <span className="btn-points">
                    {item.against_count === null ? "0" : item.against_count}
                  </span>
                </button>
              </div>
            </div>
          </>
        );
      })}
      {tabAgainst.length > 5 && showButton === true ? (
        <Link to="/my-profile">
          <button
            className="btn- btn-see"
            onClick={() => {
              setSliceCount(tabAgainst.length);
              setShowButton(false);
            }}>
            See More
          </button>
        </Link>
      ) : (
        ""
      )}
    </div>
  );
}

export default TabAgainst;
