import {Route, Redirect} from "react-router-dom";
import {toast} from "react-toastify";
// export function PrivateRoute({ children, ...rest }) {

//     const isAuthenticate=localStorage.getItem("isAuthenticate")
//     const {location}=useLocation()

//     if(!isAuthenticate){
//         toast.error("Please Log in!")
//         return(
//             <Redirect
//             to={{
//               pathname: "/",
//              replace,
//               state: { from: location },
//             }}
//           />

//         )
//     }
//     return (
//       <Route
//         {...rest}
//         render={children}
//       />
//     );
//   }
export function PrivateRoute({children, ...rest}) {
  const isAuthenticate = localStorage.getItem("isAuthenticate");
  return (
    <Route
      {...rest}
      render={({location}) => {
        if (!isAuthenticate) {
          toast.error("Please Login");
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {from: location},
              }}
            />
          );
        }
        return children;
      }}
    />
  );
}
