import React from "react";
import "./footer.scss";
import {Link} from "react-router-dom";

function Footer() {
  const {pathname} = window.location;
  if (pathname.includes("/verify")) {
    return null;
  }
  return (
    <div className="for-stance">
      <div className="footer section-gap">
        <div className="container">
          <div className="footer-content">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <Link to="/">
                  <p className="text line-height">
                    Mass<span>Debaters</span>
                  </p>
                </Link>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 hidden-block"></div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <Link to="/create-debate">
                  <p className="text">Create</p>
                </Link>
                <Link to="/">
                  <p className="text">Explore</p>
                </Link>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-4">
                <Link to="/profile-moderator">
                  <p className="text"> Moderators</p>
                </Link>

                <Link to="/rules-policies">
                  <p className="text">Rules & Policies</p>
                </Link>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-4">
                <Link to="/contact-us">
                  <p className="text">Contact Us</p>
                </Link>
              </div>
            </div>
          </div>
          <div className="social-media">
            <div className="media-icons">
              <p className="icons">
                <a
                  href="https://www.facebook.com/MassDebatersOfficial/"
                  target="_blank"
                  rel="noreferrer">
                  <i class="bx bxl-facebook-circle"></i>
                </a>
              </p>
              <p className="icons">
                <a
                  href="https://www.twitter.com/massdeb8ers"
                  target="_blank"
                  rel="noreferrer">
                  <i class="bx bxl-twitter"></i>
                </a>
              </p>
              <p className="icons">
                <i class="bx bxl-skype"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
