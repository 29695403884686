//@ts-check
import * as React from "react";
import NotFoundPage from "../notFoundPage/notFoundPage";
import {Comment} from "./Comment";

/**@type {React.FC<{comments:Array<any>,limit:number,stance:"For"|"Against",setCommentsCount:React.Dispatch<React.SetStateAction<number>>,commentingId:null|number,setCommentingId:(n:number)=>void,refetchComments:()=>Promise<any>}>} */
export const Comments = ({
  comments,
  limit,
  stance,
  commentingId,
  setCommentingId,
  setCommentsCount,
  refetchComments,
}) => {
  let stanceFor = stance === "For";
  return (
    <div className={`col-lg-6 col-md-6 col-sm-12 ${stanceFor && "br-1"}`}>
      <div className={stanceFor ? "for-section" : "against-section"}>
        <div className={stanceFor ? "for-arrow" : "against-arrow"}>
          <span className={stanceFor ? "f-right" : "f-left"}>{stance}</span>
        </div>
        {stance === "For" && <div className="circle"></div>}
      </div>
      {comments.length > 0 ? (
        comments?.slice(0, limit)?.map((comment, index) => {
          return (
            <Comment
              key={comment.cid}
              stance={stance}
              comment={comment}
              setCommentingId={setCommentingId}
              commentingId={commentingId}
              refetchComments={refetchComments}
              //setCommentIndex={setCommentIndex}
              //showCommentBox={showCommentBox}
              //subCommentFetch={subCommentFetch}
              //open={open}
              //setOpen={setOpen}
              //subComment={subComment}
              //commentIndex={commentIndex}
              //item={item}
              //index={index}
              //likeUpdate={likeUpdate}
              //setLikeUpdate={setLikeUpdate}
            />
          );
        })
      ) : (
        <NotFoundPage title={`${stance} comments`} image="nodata.svg" />
      )}
      {comments.length > limit ? (
        <div className="text-center">
          <button
            type="submit"
            name="seemore"
            className="btn- popular-btn mb-20"
            onClick={() => {
              // setLengthCount(userOpinion.length);
              //  setShowButton(false);
              setCommentsCount(comments.length);
            }}>
            See More
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
