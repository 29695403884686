import AddStance from "./addStance";
import CardFile from "../cardFile/cardFile";
import Opinion from "../cardFile/opinion";
import TextEditor from "../textEditor/textEditor";
import {getSpecificDebate} from "../Api/GetApi";
import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import "./stance.scss";
import RejectPage from "../RejectPage/RejectPage";

const Stance = () => {
  // const {loader} = useContext(GlobalContext);
  // const [isLoading, setIsLoading] = useState(true);

  const {slug} = useParams();
  const [likeDislike, setLikeDislike] = useState(false);
  const [specificData, setSpecificData] = useState([]);
  useEffect(() => {
    if (slug) {
      getSpecificDebate(slug).then(res => {
        setSpecificData(res.data);
      });
      window.scrollTo(0, 0);
    }
  }, [likeDislike, slug]);

  return (
    <>
      {specificData.length > 0 && (
        <div className="stance-page">
          {specificData[0]?.status === "rejected" ? (
            <RejectPage />
          ) : (
            <>
              <AddStance
                data={specificData}
                stance={slug}
                slug={slug}
                likeDislike={likeDislike}
                setLikeDislike={setLikeDislike}
              />
              <div className="stance-wrapper">
                <>
                  <CardFile data={specificData} slug={slug} />

                  <Opinion data={specificData} slug={slug} />
                  <TextEditor data={specificData} slug={slug} stance={slug} />
                </>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Stance;
