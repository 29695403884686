//@ts-check
import React, {useState} from "react";
import {domain} from "../Api/AxiosInstance";
import TimeAgo from "timeago-react";
import ShowMoreText from "react-show-more-text";
import {CommentArea} from "./commentArea";
import parse from "html-react-parser";
import {isAuthenticate} from "../../Utils/Utils";
import {placeholderImageUrl} from "../Api/GetApi";
import {commentDislike, commentLike} from "../Api/PostApi";
import {useEffect} from "react";
import LikeButton from "./LikeButton";
import DislikeButton from "./DislikeButton";

/**@type {"liked"|"disliked"|""} */
let initialInterAction = "";

/**@type {React.FC<{stance:"For"|"Against",comment:any,commentingId:null|number,setCommentingId:(n:number)=>void,refetchComments:()=>Promise<any>}>} */
export const Comment = ({
  stance,
  comment,
  commentingId,
  setCommentingId,
  refetchComments,
}) => {
  const [interAction, setInterAction] = useState(initialInterAction);

  const likeComment = async () => {
    const postData = {
      comment_id: comment.cid,
    };

    commentLike(postData).then(res => {
      if (res.status === 200) {
        if (interAction === "liked") {
          setInterAction("");
        } else {
          setInterAction("liked");
        }
        refetchComments();
      }
    });
  };

  const dislikeComment = async () => {
    const postData = {
      comment_id: comment.cid,
    };
    commentDislike(postData).then(res => {
      if (res.status === 200) {
        if (interAction === "disliked") {
          setInterAction("");
        } else {
          setInterAction("disliked");
        }
      }
      refetchComments();
    });
  };

  useEffect(() => {
    if (comment.like_event) {
      setInterAction("liked");
    }
    if (comment.dislike_event) {
      setInterAction("disliked");
    }
  }, [comment.like_event, comment.dislike_event]);

  const dislikeCount = Number(comment.comment_dislike_count || 0);
  const likeCount = Number(comment.comment_like_count || 0);

  return (
    <div className="for-component">
      <div
        className={`card-section ${stance === "Against" ? "ml-20" : "mr-20"}`}>
        <div className="space-between">
          <div className="space-between">
            <img
              src={
                comment.commenter_image
                  ? domain + "/uploads/" + comment.commenter_image
                  : placeholderImageUrl
              }
              alt="img"
              className="profile"
            />

            <div className="description">
              <h4 className="name">{comment.commenter_name}</h4>

              <p className="time">
                <TimeAgo datetime={comment.commented_date} />
              </p>
            </div>
          </div>
        </div>
        <div>
          <p className="gray-text">
            <p className="key-argument">{comment.comment_title}</p>
            <ShowMoreText
              lines={3}
              more="Show more"
              less="Show less"
              className="content-css"
              anchorClass="my-anchor-css-class"
              expanded={false}
              truncatedEndingComponent={"... "}>
              {parse(`${comment.cmt}`)}
            </ShowMoreText>
          </p>
          {!isAuthenticate() ? (
            <>
              <div className="display">
                <LikeButton
                  count={likeCount}
                  interAction={interAction}
                  onClick={likeComment}
                />
                <DislikeButton
                  count={dislikeCount}
                  interAction={interAction}
                  onClick={dislikeComment}
                />
                <p
                  className="view-icon cw "
                  aria-controls="example-collapse-text"
                  // aria-expanded={open}
                  onClick={() => {
                    if (commentingId === comment.cid) {
                      setCommentingId(null);
                    } else {
                      setCommentingId(comment.cid);
                    }
                  }}>
                  <i className="bx bxs-comment"></i>
                  <span className="number">
                    {comment.com_detail_count === null
                      ? 0
                      : comment.com_detail_count}
                  </span>
                </p>
              </div>
              {Number(commentingId) === Number(comment.cid) && (
                <CommentArea commentId={comment.cid} />
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
