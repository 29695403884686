import React from "react";
import Modal from "react-awesome-modal";

import {DeletePopUp} from "./DeletePopUp";
import {EditPopUp} from "./EditPopUp";

export const CustomPopUp = props => {
  return (
    <div>
      <Modal
        visible={props.visible}
        width="400"
        height="150"
        effect="fadeInUp"
        onClickAway={() => props.closeModal()}>
        <div>
          {props.type === "delete" ? (
            <DeletePopUp
              action={props.action}
              id={props.id}
              closeModal={props.closeModal}
            />
          ) : (
            <EditPopUp
              subComment={props.subComment}
              action={props.action}
              id={props.id}
              closeModal={props.closeModal}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};
