import {Formik, Form, Field, ErrorMessage} from "formik";
import React, {useContext} from "react";
import * as Yup from "yup";
import {Contactus} from "../Api/PostApi";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {GlobalContext} from "../Context/GlobalContext";
import "./contact.scss";
import Loader from "react-loader-spinner";
import MetaConfigure from "../Helmet/Helmet";

const Contact = () => {
  const {successMessage, errorMessage} = useContext(GlobalContext);
  return (
    <Formik
      initialValues={{
        name: "",
        address: "",
        contact: "",
        email: "",
        message: "",
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .min(2, "Must be 2 characters or more")
          .required("Required!!"),

        address: Yup.string()
          .min(4, "Must be 4 characters or more")
          .required("Required!!"),
        contact: Yup.string()
          .min(10, "Must be 10 characters")
          .required("Required!!"),

        email: Yup.string()
          .email("Invalid email addresss")
          .required("Required!!"),

        message: Yup.string()
          // .matches(/^[aA-zZ\s]+$/)
          .required("Required!!"),
      })}
      onSubmit={(values, {setSubmitting, resetForm}) => {
        setSubmitting(true);
        const postData = new FormData();
        postData.append("name", values.name);
        postData.append("address", values.address);
        postData.append("contact", values.contact);
        postData.append("email", values.email);
        postData.append("message", values.message);

        Contactus(postData)
          .then(res => {
            if (res.data.status === 200) {
              successMessage("Form submitted successfully !");
              resetForm({
                values: {
                  name: "",
                  address: "",
                  contact: "",
                  email: "",
                  message: "",
                },
              });
            } else {
              errorMessage(res.data.message);
            }
          })
          .finally(() => setSubmitting(false));
      }}>
      {formik => (
        <Form>
          <MetaConfigure title="Contact Us - Mass Debaters" />

          <div className="container">
            <div className="field-content">
              <div className="card-section">
                <h3 className="main-title">Contact Us</h3>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>Name</label>
                      <Field
                        name="name"
                        type="text"
                        placeholder="Enter name"
                        className="input-field"
                      />
                      <span className="error">
                        <ErrorMessage name="name" />
                      </span>
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <Field
                        name="email"
                        type="email"
                        placeholder="Enter email"
                        className="input-field"
                      />
                      <span className="error">
                        <ErrorMessage name="email" />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>Contact</label>
                      <Field
                        name="contact"
                        type="text"
                        pattern="^[0-9]*$"
                        maxLength="10"
                        placeholder="Enter contact"
                        className="input-field"
                      />
                      <span className="error">
                        <ErrorMessage name="contact" />
                      </span>
                    </div>
                    <div className="form-group">
                      <label>Address</label>
                      <Field
                        name="address"
                        type="text"
                        placeholder="Enter address"
                        className="input-field"
                      />
                      <span className="error">
                        <ErrorMessage name="address" />
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-12 col-sm-12 col-md-12">
                    <div className="form-group">
                      <label>Your Message</label>
                      <Field
                        as="textarea"
                        className="input-field"
                        name="message"
                        placeholder="Enter your message"
                        type="text"
                      />
                      <span className="error">
                        <ErrorMessage name="message" />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="clearfix"></div>
                {/* <input type="submit" value="Submit" /> */}
                {/* <button type="submit" onClick={showToast} className="main-btn">
              Submit
            </button> */}

                <button
                  type="submit"
                  className="main-btn"
                  disabled={formik.isSubmitting}>
                  {formik.isSubmitting ? (
                    <Loader width={60} height={20} color="#FFFFFF" />
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
                <ToastContainer />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Contact;
