import React, {useState, useEffect, useContext} from "react";
import Modal from "react-awesome-modal";
import {Form, Formik, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import "./Signup.scss";
import {getCountryList} from "../Api/GetApi";
import "react-toastify/dist/ReactToastify.css";
import {SignUpForm} from "../Api/PostApi";
import {GlobalContext} from "../Context/GlobalContext";
import profileImg from "../../assets/images/profile.png";
import moment from "moment";
import Loader from "react-loader-spinner";

const SignUp = props => {
  const [visible, setVisible] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const {successMessage, errorMessage} = useContext(GlobalContext);
  const [image, setImage] = useState(`${profileImg}`);

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    getCountryList().then(res => {
      setCountryList(res.data);
    });
  }, []);

  return (
    <>
      <div className="sign-up-section">
        <div className="popup btn">
          <div
            className="sign-up-btn btn"
            onClick={() => {
              openModal();
            }}>
            Sign Up
          </div>
          <Modal
            visible={visible}
            width="545"
            height="526"
            effect="fadeInUp"
            onClickAway={() => closeModal()}
            closeModal>
            <div className="form-section scroll-bar">
              <Formik
                initialValues={{
                  image: "",
                  email: "",
                  password: "",
                  name: "",
                  user_name: "",
                  dob: "",
                  country: "",
                  accept: "",
                }}
                validationSchema={Yup.object({
                  name: Yup.string()
                    .min(2, "Must be 2 characters or more")
                    .required("Required"),

                  email: Yup.string()
                    .email("Invalid email addresss`")
                    .required("Required"),

                  password: Yup.string()
                    .required("Required")
                    .min(6, "Must be 6 characters or more"),
                  accept: Yup.bool()
                    .required("Required")
                    .oneOf([true], "Required"),
                  confirm_password: Yup.string().when("password", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                      [Yup.ref("password")],
                      "Password Doesn't Match"
                    ),
                  }),
                  dob: Yup.string().required("Required"),
                  country: Yup.string().required("Required"),
                  user_name: Yup.string().required("Required"),
                })}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                  setSubmitting(true);
                  var formData = new FormData();
                  formData.append("image", values.image);
                  formData.append("name", values.name);
                  formData.append("user_name", values.user_name);
                  formData.append("email", values.email);
                  formData.append("country", values.country);
                  formData.append("password", values.password);
                  formData.append("dob", values.dob);
                  formData.append("consent", values.accept);
                  SignUpForm(formData)
                    .then(({data}) => {
                      closeModal();
                      successMessage(
                        "Please check your email to verify your account!"
                      );
                      resetForm({
                        values: {
                          name: "",
                          username: "",
                          password: "",
                          confirm_password: "",
                          email: "",
                          dob: "",
                          image: "",
                          accept: "",
                        },
                      });
                    })
                    .catch(error => {
                      errorMessage(error.response.data.message);
                    })
                    .finally(() => setSubmitting(false));
                }}>
                {formik => {
                  return (
                    <Form>
                      <div className="space-between">
                        <h4 className="contents" style={{fontWeight: "600"}}>
                          Sign up
                        </h4>
                        <div
                          className="close-btn btn"
                          onClick={() => closeModal()}>
                          x
                        </div>
                      </div>
                      <div className="signup-section">
                        <div className="contents">
                          <label>Image</label>
                          <div className="avatar-upload">
                            <div className="avatar-edit">
                              <input
                                type="file"
                                id="imageUpload2"
                                accept="image/*"
                                onChange={e => {
                                  const image = e.target.files[0];
                                  const url = URL.createObjectURL(image);
                                  setImage(url);
                                  formik.setFieldValue("image", image);
                                }}
                              />
                              <label htmlFor="imageUpload2">
                                <i className="bx bxs-pencil"></i>
                              </label>
                            </div>
                            <div className="avatar-preview">
                              <div
                                id="imagePreview"
                                style={{
                                  backgroundImage: `url(${image})`,
                                }}></div>
                            </div>
                          </div>

                          <span className="error">
                            <ErrorMessage name="image" />
                          </span>
                        </div>
                        <div class="contents">
                          <label>Name</label>
                          <Field
                            name="name"
                            type="text"
                            placeholder="Enter your Name"
                            className="input-field"
                          />
                          <span className="error">
                            <ErrorMessage name="name" />
                          </span>
                        </div>
                        <div class="contents">
                          <label>Username</label>
                          <Field
                            name="user_name"
                            type="text"
                            placeholder="Enter your Username"
                            className="input-field"
                          />
                          <span className="error">
                            <ErrorMessage name="user_name" />
                          </span>
                        </div>
                        <div class="contents">
                          <label>Email</label>

                          <Field
                            name="email"
                            type="email"
                            placeholder="Enter your Email"
                            className="input-field"
                          />
                          <span className="error">
                            <ErrorMessage name="email" />
                          </span>
                        </div>
                        <div class="contents">
                          <label>Password</label>
                          <Field
                            name="password"
                            type="password"
                            placeholder="Enter your Password"
                            className="input-field"
                          />
                          <span className="error">
                            <ErrorMessage name="password" />
                          </span>
                        </div>
                        <div class="contents">
                          <label>Confirm Password</label>
                          <Field
                            name="confirm_password"
                            type="password"
                            placeholder="Confirm Password"
                            className="input-field"
                          />
                          <span className="error">
                            <ErrorMessage name="confirm_password" />
                          </span>
                        </div>
                        <div class="contents">
                          <label>DOB</label>
                          <Field
                            max={moment().format("YYYY-MM-DD")}
                            name="dob"
                            type="date"
                            className="input-field"
                          />
                          <span className="error">
                            <ErrorMessage name="dob" />
                          </span>
                        </div>
                        <div class="contents">
                          <label for="country">Choose Country</label>
                          <Field
                            name="country"
                            as="select"
                            className="input-field">
                            <option>Select Country</option>
                            {countryList.map((list, index) => {
                              return (
                                <option value={list} key={index}>
                                  {list}
                                </option>
                              );
                            })}
                          </Field>
                          <span className="error">
                            <ErrorMessage name="country" />
                          </span>
                        </div>
                        <div class="contents password-block"></div>
                        <div>
                          {/* <input type="file" className="image-upload" /> */}
                          {/* <img src={this.state.file} /> */}
                        </div>

                        <div className="cw space-between">
                          <div class="contents">
                            <div className="accept-terms">
                              <Field
                                name="accept"
                                type="checkbox"
                                placeholder=""
                                className=""
                              />
                              <span className="font-500 accept">
                                {" "}
                                I accept{" "}
                                <span className="font-500 term-condition">
                                  Terms and Conditions
                                </span>
                              </span>
                            </div>

                            <span className="error">
                              <ErrorMessage name="accept" />
                            </span>
                          </div>
                        </div>

                        <button
                          type="submit"
                          className="main-btn  login-btn"
                          disabled={formik.isSubmitting}>
                          {formik.isSubmitting ? (
                            <Loader width={70} height={15} color="#FFFFFF" />
                          ) : (
                            <span>Sign Up</span>
                          )}
                        </button>

                        <div>
                          <span
                            onClick={() => closeModal()}
                            className="sign-up">
                            Already have an account?{" "}
                            <span className="log-btn">Log in</span>
                          </span>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default SignUp;
