import React from "react";
import "./ThankyouPage.scss";
import {Link} from "react-router-dom";

function ThankyouPage() {
  return (
    <div className="thankyou-section">
      <div className="container">
        <div className="card-section">
          <div className="row justify-content-md-center">
            <div className="col-md-7">
              <div className="thankyou-content">
                <h1 className="main-title">THANK YOU FOR JOINING THE TEAM!</h1>
                <h2>Thank you for applying to become a moderator</h2>

                <p>
                  Your request has been received for the user to a moderator,
                  after approve your request then you can start moderating new
                  argument and debate submissions, simply click on the
                  MODERATORS mean item to see a debate with new content
                  available.{" "}
                </p>
                <Link to="/">Back to Homepage</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankyouPage;
