import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import React, {useEffect} from "react";
import "./TabComponent.scss";
import Debates from "../Debates/Debates";
import {useState} from "react";

export const TabComponent = props => {
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    if (props.changeTab === true) {
      setTabIndex(1);
    }
  }, [props.changeTab]);

  return (
    <div className="tab-component">
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList>
          {props?.title?.map((item, index) => (
            <Tab
              key={index}
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                color: " #617562",
              }}>
              {item}
            </Tab>
          ))}
        </TabList>

        {props?.debateData?.map((item, index) => {
          return (
            <TabPanel>
              <Debates
                key={index}
                item={item}
                type={index}
                sliceState="true"
                refetch={props.refetch}
              />
            </TabPanel>
          );
        })}
      </Tabs>
    </div>
  );
};
