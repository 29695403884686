import React, {useState, useEffect, useContext} from "react";
import "./CreateDebate.scss";
import {getCategoryList} from "../Api/GetApi";
import {Form, Formik, Field, ErrorMessage} from "formik";
import {createDebate} from "../Api/PostApi";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {GlobalContext} from "../Context/GlobalContext";
import * as Yup from "yup";
import {isAuthenticate} from "../../Utils/Utils";

function Debate() {
  const [categoryList, setCategoryList] = useState([]);
  const {successMessage, errorMessage} = useContext(GlobalContext);

  useEffect(() => {
    getCategoryList().then(res => {
      setCategoryList(res.data);
    });
  }, []);

  return (
    <>
      <div className="debate-section section-gap">
        <div className="container">
          <div className="card-section">
            <h1 className="main-title">Create new Debate</h1>
            <div className="debate-content">
              <Formik
                initialValues={{
                  category_id: "",
                  title: "",
                  content: "",
                  image_url: "",
                  opinion: "",
                  // comment_types_id: "",
                }}
                validationSchema={Yup.object({
                  category_id: Yup.string().required("Plese select category"),
                  // comment_types_id: Yup.string().required(
                  //   "Please select your stance"
                  // ),
                  opinion: Yup.string()
                    .min(2, "Must be 2 characters or more")
                    .required("Please write the comment"),
                  title: Yup.string()
                    .min(2, "Must be 2 characters or more")
                    .required("Please enter title"),
                })}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                  const postData = new FormData();
                  postData.append("category_id", values.category_id);
                  postData.append("title", values.title);
                  postData.append("opinion", values.opinion);
                  postData.append("image_url", values.image_url);
                  postData.append("content", values.content.length > 0);
                  // postData.append(
                  //   "comment_types_id",
                  //   values.comment_types_id === "for" ? "1" : "2"
                  // );
                  console.log("postData", postData);

                  if (isAuthenticate()) {
                    errorMessage("Please login first to continue !");
                    setSubmitting(false);
                  } else {
                    createDebate(postData).then(({data}) => {
                      if (data.status === 200) {
                        successMessage(data.message);
                        resetForm();
                      } else {
                        errorMessage("Something went wrong !");
                      }
                      setSubmitting(false);
                    });
                  }
                }}>
                {formik => {
                  return (
                    <Form>
                      <div className="category-section">
                        <p>Select Category<i>*</i></p>
                        <Field
                          name="category_id"
                          className="select-shadow"
                          as="select">
                          <option value="">Select Category</option>
                          {categoryList?.map((item, index) => {
                            return (
                              <option value={item.value} key={index}>
                                {item.category_name}
                              </option>
                            );
                          })}
                        </Field>
                        <span className="error">
                          <ErrorMessage name="category_id" />
                        </span>
                      </div>
                      <div className="category-section text">
                        <p>Title<i>*</i></p>
                        <Field
                          name="title"
                          type="text"
                          placeholder="Enter title here"
                        />
                        <span className="error">
                          <ErrorMessage name="title" />
                        </span>
                      </div>
                      {/* <div className="stance-block category-section ">
                        <p>Stance</p>
                        <div className="stance-radio">
                          <div className="space">
                            <label>
                              <Field
                                name="comment_types_id"
                                value="for"
                                className=""
                                type="radio"
                              />
                              <span class="text-sm">For</span>
                            </label>
                          </div>
                          <div className="space">
                            <label>
                              <Field
                                name="comment_types_id"
                                value="against"
                                className=""
                                type="radio"
                              />
                              <span class="text-sm">Against</span>
                            </label>
                          </div>
                        </div>
                        <span className="error">
                          <ErrorMessage name="comment_types_id" />
                        </span>
                      </div> */}
                      {/* <div className="category-section text">
                        <p>Title</p>
                        <Field
                          name="opinion"
                          type="text"
                          placeholder="Enter your opinion"
                        />
                      </div> */}
                      {
                        <div className="category-section">
                          <p>Description<i>*</i></p>
                          <div className="ck-section">
                            <div className="editor-tab">
                              <div className="ckeditor-wrapper">
                                <div className="form-group">
                                  <div className="ckeditor-wrapper">
                                    <Field
                                      as="textarea"
                                      name="opinion"
                                      rows="6"
                                      placeholder="Write Statement"
                                      className="input-field"
                                    />
                                    {/* <CkEditor /> */}
                                    <span className="error">
                                      <ErrorMessage name="opinion" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      {!formik.isSubmitting && (
                        <div className="image-section">
                          <p>Debate cover Image <i className="optional">(Optional)</i></p>
                          <input
                            className="choose-file"
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            onChange={e => {
                              const image = e.target.files[0];
                              formik.setFieldValue("image_url", image);
                            }}
                          />
                          <span className="error">
                            <ErrorMessage name="image_url" />
                          </span>
                        </div>
                      )}

                      <div className="clearfix"></div>

                      <button
                        className="popular-btn btn-for"
                        type="submit"
                        disabled={formik.isSubmitting}>
                        Create
                      </button>
                      <ToastContainer />
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Debate;
