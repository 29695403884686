//@ts-check
import React from "react";

/**@type {React.FC<{
 * onClick:React.MouseEventHandler<HTMLElement>,
 * count:number,
 * interAction:""|"liked"|"disliked"
 * }>} */
const DislikeButton = ({interAction = "", count = 0, onClick}) => {
  return (
    <p
      className={[
        "view-icon",
        "cw",
        "dislike-color",
        interAction === "disliked" && "active-dis-like",
      ].join(" ")}>
      <i className="bx bxs-dislike" onClick={onClick}></i>
      <span className="number">{count}</span>
    </p>
  );
};

export default DislikeButton;
