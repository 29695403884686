import {QueryClient} from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const isAuthenticate = () =>
  localStorage.getItem("isAuthenticate") == null ||
  localStorage.getItem("isAuthenticate") === false;
