import NavBar from "./Layout/Navbar/Navbar";
import Footer from "./Layout/Footer/footer";
import Stance from "./components/addStance/Stance";
import Signup from "./components/Signup/Signup";
import EditPopUp from "./components/Popup/Editform/Editpop";

import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Notification from "./components/Notification/Notification";
import MyProfile from "./components/myProfile/MyProfile";
import ProfileModerator from "./components/ProfileModerator/ProfileModerator";
import BecomeModerator from "./components/becomeModerator/BecomeModerator";
import "../src/assets/styles/main.scss";
import ThankyouPage from "./components/ThankyouPage/ThankyouPage";
import RulesPolicies from "./components/RulesPolicies/RulesPolicies";
import CreateDebate from "./components/CreateDebate/CreateDebate";
import ContactUs from "./components/ContactUs/ContactUs";
import SeeMore from "./components/SeeMore/SeeMore";
import subCategory from "./components/subCategory/subCategory";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import {GlobalContext} from "./components/Context/GlobalContext";
import {QueryClientProvider} from "react-query";
import {queryClient} from "./Utils/Utils";
import {Index} from "./components/HomPage";
import {useEffect, useState, useContext} from "react";
import {getNotifications} from "./components/Api/GetApi";
import {ConfirmPassword} from "./components/ConfirmPassword/ConfirmPassword";
import {isAuthenticate} from "./Utils/Utils";

import {Spinner} from "./components/Loader/Loader";
import {SWRConfig} from "swr";
import {EmailVerification} from "./components/EmailVerification/EmailVerification";
import {PrivateRoute} from "./components/AuthRouter/AuthRouter";
import MetaConfigure from "./components/Helmet/Helmet";

function App() {
  const [notificationData, setNotificationData] = useState({});
  useEffect(() => {
    if (isAuthenticate()) {
      return;
    }
    getNotifications()
      .then(response => {
        setNotificationData(response.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const {showLoader} = useContext(GlobalContext);
  return (
    <QueryClientProvider client={queryClient}>
      <SWRConfig>
        <Router>
          <MetaConfigure title="Mass Debaters" />

          <div className={`${showLoader ? "hidden" : ""}`}>
            <NavBar notificationData={notificationData} />
            <div className="bodyheight">
              <Switch>
                <Route exact path="/">
                  <Index />
                </Route>
                <Route exact path="/notification">
                  <div className="notification-single-page">
                    <div className="container">
                      <Notification
                        notificationData={notificationData}
                        sliceState="true"
                      />
                    </div>
                  </div>
                </Route>

                <Route exact path="/profile-moderator">
                  <ProfileModerator />
                </Route>

                <Route exact path="/loader-spinner">
                  <Spinner />
                </Route>

                <Route exact path="/password/reset/:token">
                  <ConfirmPassword />
                </Route>
                <PrivateRoute path="/my-profile">
                  <MyProfile />
                </PrivateRoute>

                {/* <Route exact path="/my-profile" component={MyProfile} /> */}

                <Route exact path="/debates/:type" component={SeeMore} />

                <Route exact path="/signup">
                  <Signup />
                  <EditPopUp />
                </Route>

                <Route exact path="/verify/:id" component={EmailVerification} />
                {/* <Route exact path="/verify" component={Verify} /> */}

                <Route exact path="/contact-us">
                  <ContactUs />
                </Route>

                {/* <Route exact path="/sub-comment">
                  <SubComment />
                </Route> */}

                <Route exact path="/become-moderator">
                  <BecomeModerator />
                </Route>

                <Route exact path="/thankyou-page">
                  <ThankyouPage />
                </Route>

                <PrivateRoute exact path="/create-debate">
                  <CreateDebate />
                </PrivateRoute>

                <Route exact path="/forgot-password">
                  <ForgotPassword />
                </Route>

                <Route
                  exact
                  path="/sub-category/:slug"
                  component={subCategory}
                />

                <Route exact path="/rules-policies">
                  <RulesPolicies />
                </Route>
                {/* <Route exact path="/stance/:stance/:id" component={Stance} /> */}
                <Route exact path="/stance/:slug" component={Stance} />
              </Switch>
            </div>
            <Footer />
          </div>
        </Router>
      </SWRConfig>
    </QueryClientProvider>
  );
}

export default App;
