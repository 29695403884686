//@ts-check

import React from "react";
import "react-toastify/dist/ReactToastify.css";
import {useForAgainst} from "../Api/GetApi";
import "./opinion.scss";
import "./cardFile.scss";
import {useState} from "react";
import {Comments} from "./Comments";

/**@type {React.FC<{id:string}>} */
function Opinion({slug}) {
  const [commentsCount, setCommentsCount] = useState(5);
  const [commentingId, setCommentingId] = useState(null);

  const {data, refetch: refetchComments} = useForAgainst(slug);

  console.log({data});

  const forComments = React.useMemo(
    () => data.filter(filterItem => filterItem.comment === "For"),
    [data]
  );

  const againstComments = React.useMemo(
    () => data.filter(filterItem => filterItem.comment === "Against"),
    [data]
  );

  return (
    <div className="custom-section opnion-section">
      <div className="container">
        <div className="row"></div>
        <div className="clearfix"></div>

        {data.length <= 0 && (
          <div className="back-color">
            <h5>No comments here!</h5>
          </div>
        )}
        <div className="row">
          <Comments
            comments={forComments}
            commentingId={commentingId}
            setCommentingId={setCommentingId}
            limit={commentsCount}
            stance="For"
            setCommentsCount={setCommentsCount}
            refetchComments={refetchComments}
          />
          <Comments
            comments={againstComments}
            limit={commentsCount}
            commentingId={commentingId}
            setCommentingId={setCommentingId}
            stance="Against"
            setCommentsCount={setCommentsCount}
            refetchComments={refetchComments}
          />
        </div>
      </div>
    </div>
  );
}

export default Opinion;
