import React from "react";
import "./cardFile.scss";
import {domain} from "../Api/AxiosInstance";
// import {Like} from "../Api/PostApi";
// import {disLike} from "../Api/PostApi";
import parse from "html-react-parser";
import ShowMoreText from "react-show-more-text";
// import {isAuthenticate} from "../../Utils/Utils";
import {placeholderImageUrl} from "../Api/GetApi";

const CardFile = props => {
  // const [activelike, setActivelike] = useState(false);
  // const [activeDislike, setActiveDislike] = useState(false);
  // const categoryChecker = category => {
  //   switch (category) {
  //     case "politics":
  //       return "purple-btn popular-btn";
  //     case "health":
  //       return "purple-btn popular-btn";
  //     case "philoshopy":
  //       return "blue-btn popular-btn";
  //     case "religion":
  //       return "pink-btn popular-btn";
  //     case "science":
  //       return "yellow-btn popular-btn";
  //     case "education":
  //       return "pink-btn popular-btn";
  //     case "games":
  //       return "yellow-btn popular-btn";
  //     default:
  //       return "popular-btn";
  //   }
  // };

  // const debateLike = user_front_id => {
  //   const postData = {
  //     debate_id: props.id,
  //     user_front_id: user_front_id,
  //   };
  //   Like(postData).then(res => {
  //     if (res.status === 200) {
  //       props.setLikeDislike(!props.likeDislike);
  //       return;
  //     }
  //   });
  // };

  // const debateDisLike = user_front_id => {
  //   const postData = {
  //     debate_id: props.id,
  //     user_front_id: user_front_id,
  //   };
  //   disLike(postData).then(res => {
  //     if (res.status === 200) {
  //       props.setLikeDislike(!props.likeDislike);
  //       return;
  //     }
  //   });
  // };

  return (
    <div className="custom-section stance-description">
      {props.data.map((item, index) => (
        <div key={index} className="container">
          <div className="card-section card-shadow">
            <div className="space-between">
              <div className="title-img">
                <div className="picture">
                  <img
                    className="image-uploads"
                    src={domain + "/uploads/" + item.image}
                    alt="img"
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = placeholderImageUrl;
                    }}
                  />
                </div>
                <div className="">
                  <h3 className="user-name">{item.user_name}</h3>
                  <p className="datee">{item.created_at}</p>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  name="health"
                  className="popular-btn category-btn"
                  style={{
                    color: item.color,
                    background: item.color_opacity,
                  }}>
                  {item.category_name}
                </button>
              </div>
            </div>

            <p className="custom-text">{item.title}</p>
            <p className="gray-text show-block">
              <ShowMoreText
                lines={7}
                more="Show more"
                less="Show less"
                className="content-css"
                anchorClass="my-anchor-css-class"
                expanded={false}
                truncatedEndingComponent={"... "}>
                {parse(`${item.opinion}`)}
              </ShowMoreText>
            </p>
            <p className="gray-text hidden-block">{parse(`${item.opinion}`)}</p>
            {/* {!isAuthenticate() && (
              <div className="display">
                <div
                  className={`view-icon link-color ${
                    activelike === true || props?.data[0]?.like_event === true
                      ? "active-like"
                      : ""
                  } `}>
                  <i
                    class="bx bxs-like btn-like"
                    onClick={() => {
                      debateLike(item.user_front_id);
                      setActivelike(prevActiveLike => !prevActiveLike);
                      setActiveDislike(false);
                    }}></i>
                  <span className="number">{item.like_count}</span>
                </div>
                <div
                  className={`view-icon cw dislike-color ${
                    activeDislike === true ||
                    props?.data[0]?.dislike_event === true
                      ? "active-dis-like"
                      : ""
                  }`}>
                  <i
                    class="bx bxs-dislike btn-dislike"
                    onClick={() => {
                      debateDisLike(item.user_front_id);
                      setActiveDislike(prevActiveDisLike => !prevActiveDisLike);
                      setActivelike(false);
                    }}></i>
                  <span className="number">{item.dislike_count}</span>
                </div>
              </div>
            )} */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardFile;
