import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "./Tabpanels.scss";
import TabFor from "../TabFor/TabFor";
import TabDebate from "../TabDebate/TabDebate";
import TabAgainst from "../TabAgainst/TabAgainst";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div className="tab-panel-section">
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    "id": `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tab-panel-section">
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example">
            <Tab label="Debates" {...a11yProps(0)} />
            <Tab label="For" {...a11yProps(1)} />
            <Tab label="Against" {...a11yProps(2)} />
            <Tab label="" {...a11yProps(3)} />
            {/* <Tab label="Agreed" {...a11yProps(4)} /> */}
            {/* <Tab label="Disagreed" {...a11yProps(5)} /> */}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <TabDebate />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TabFor index={1} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TabAgainst index={2} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          jhgfds
        </TabPanel>
        <TabPanel value={value} index={4}>
          Item Three
        </TabPanel>
        <TabPanel value={value} index={5}>
          Item Three
        </TabPanel>
      </div>
    </div>
  );
}
