import React from "react";
import "./CustomPopUp.scss";

export const DeletePopUp = props => {
  return (
    <div className="confirmtion-popup popup-padding">
      {/* <h2 className="title">Are you sure you want to do that?</h2> */}
      <h5 className="title popup-title">Are you sure you want to do that?</h5>
      <i class="bx bx-x bx-sm cross-btn" onClick={() => props.closeModal()}></i>

      <div className="btn-block">
        <button
          className="secondary btn cancel-btn"
          onClick={() => props.closeModal()}>
          Cancel
        </button>
        <button
          className="main-btn"
          onClick={() => {
            props.action(props.id);
          }}>
          OK
        </button>
      </div>
    </div>
  );
};
